export const environmentaldatas = {
  /* devop 1*/
  // baseURL: 'https://demo.fmw.app/api/',
  // appurl: 'https://demo.fmw.app/',
  // image_url: 'https://demo.fmw.app/uploads/',
  // 1507
 
  /*Live new*/
  baseURL: 'https://au.fmw.app/api',
  appurl: 'https://qr.au.fmw.app', 
  image_url: 'https://au.fmw.app/uploads/',
}; 