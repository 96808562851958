/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { CardBody, Card, Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom';
import LogoImage from '../assets/img/Logo.svg'
import './page.css';
import { getRequest } from '../hooks/axiosClient';
import Sheet from 'react-modal-sheet';
import { IoMdClose } from 'react-icons/io';

const ReportIssue = ({ setOpen, open,returnToVehicle }) => {

    const navigate = useNavigate();
    const [currentFloor, setCurrentFloor] = useState();
    const [openReportForm, setOpenReportForm] = useState(false);
    const [customerId, setCustomerId] = useState()

    const [retunModal, setReturnModal] = useState(false)
    const [modalData, setModalData] = useState(false)

    const closeReturnModal = () => {
        setReturnModal(!retunModal)
    }

    useEffect(() => {
        let projectData = JSON.parse(localStorage.getItem("project_data"));
        console.log(projectData)
        const customer_id = projectData?.enc_customer_id;
        setCustomerId(customer_id);
    }, []);

    const close = () => {
        setOpen(false);
    }

    const getFloorList = async (value) => {
        try {
            const response = await getRequest(`levels/${value?.project_id}`);
            const data = response.data ?? [];
            const floors = data.map((floor) => ({
                value: floor.enc_id,
                label: floor?.floor_plan,
                id: floor.enc_id,
            }))
            const lastFloor = floors[floors?.length - 1]?.value
            const floorid = localStorage.getItem('floorId')
            setCurrentFloor(floorid)
            console.log(response,"floorr");
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        const project = localStorage.getItem('project_data');
        const data = JSON.parse(project)
        getFloorList(data)
    }, []);

    const handleShare = async () => {
        const share_url = localStorage.getItem('share_url');
        try {
            await navigator.share({
                title: "Find my way",
                url: share_url
            });
            console.log("Shared successfully");
        } catch (error) {
            console.error("Error sharing:", error);
        }
    };

    const handleReturntovehicle = async () =>{
        let endingpoint = localStorage.getItem("share_url")?.split("/")
        try {
            const response = await getRequest(`destinations-info/${endingpoint[endingpoint.length - 1]}`);
            // console.log(response);
            setModalData(response?.data?.data)
            setReturnModal(true)
            setOpen(false) 
        } catch (error) {
            console.log(error);
        } 
      }


    const confirmReturntovehicle = () =>{
        closeReturnModal()
        setOpen(false)
        returnToVehicle()
    }

    const toggle = () => setReturnModal(!retunModal);


    return (
        <>
         <Modal isOpen={retunModal} toggle={toggle}  style={{ zIndex: '999999 !important' }} backdrop={'static'} centered>
                {/* <span  onClick={() => {
                        closeReturnModal()
                    }} className='modal-close-icon' >
                    <div className='rounded-circle close-icon-vt'>
                        <IoMdClose style={{ fontSize: '10px' }} />
                    </div>
                </span> */}
                <ModalBody style={{ padding: '30px' }} className='confirm-to-go-back'>
                    <p>
                        Would you like to set your destination to your original starting location? 
                        <br/>
                         {modalData && <span> {`${modalData?.name} (${modalData?.floor_plan})`} </span>} 
                        {/* Choose a location near you, and we will guide you back to where you began. */}
                    </p>
                     <div className='d-flex justify-content-center gap-3'> 
                        
                        <Button
                            className="btn btn-primary cancel-button m-auto" 
                            block
                            onClick={closeReturnModal}
                        >
                            No
                        </Button>

                        <Button
                            className="btn btn-primary m-auto" 
                            block
                            onClick={confirmReturntovehicle}
                        >
                            Yes
                        </Button>
                    </div> 
                </ModalBody >
            </Modal >

            <Sheet isOpen={open} onClose={() => { setOpen(false) }} disableDrag={true} className='sheet-cont'>
                <div className='modal-sheet-container'>
                    <Sheet.Container>
                        <Sheet.Content>
                            <Card className="mt-1">
                                <CardBody className='content-card'>
                                    <Row className=''>
                                        <Col sm={12} className="d-flex align-items-center align-end">
                                            <img src={LogoImage} alt="floorplan" style={{ width: '40%' }} />
                                            <div className='close-icon' onClick={close}>
                                                <AiOutlineClose />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col sm={12}>
                                            <Button
                                                size="small"
                                                key="4"
                                                className="btn Report-button"
                                                block
                                                onClick={handleReturntovehicle}
                                            >
                                                Return to starting location
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className='mt-2'>
                                        <Col sm={12}>
                                            <Button
                                                size="small"
                                                key="4"
                                                className="btn Report-button"
                                                block
                                                onClick={handleShare}
                                            >
                                                Share map
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm={12}>
                                            <Link  >
                                                <Button size="small" key="4" className="btn Report-button" block onClick={() => {
                                                    navigate(`/report-an-issue/${customerId}`)
                                                }}>Report an issue
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2 mb-2'>
                                        <Col sm={12} >
                                            <a href="https://fmw.app/" target="_blank" rel="noopener noreferrer">
                                                <Button size="small" key="4" className="btn Report-button" block><strong>Find My Way</strong>&nbsp;for your business
                                                </Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Sheet.Content>
                    </Sheet.Container>
                </div>
                <Sheet.Backdrop onClick={(e) => e.stopPropagation()} />
            </Sheet >
        </>
    );
}

export default ReportIssue;
