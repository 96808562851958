/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from "react";
import { CardBody, Card, Row, Col, Button } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { getRequest, postRequest } from "../hooks/axiosClient";
import { environmentaldatas } from "../constant/defaultValues";
import "./page.css";
import Sheet from "react-modal-sheet";
import ImageSlider from "../helpers/ImageSlider";

const { image_url } = environmentaldatas;
const SetDestination = ({
  open,
  setOpen,
  id,
  type,
  closes,
  setCurrentFloor,
  setFromDestinationData,
}) => {
  const navigate = useNavigate();

  const close = () => {
    setOpen(false);
    setLocationDtls([]);
    if (setCurrentFloor && type == "from") {
      setCurrentFloor();
      // if (setFromDestinationData) {
      //   setFromDestinationData();
      // }
    }
  };

  const [locationDtls, setLocationDtls] = useState([]);
  const [hours, setHours] = useState([]);
  const project = localStorage.getItem("project_data");
  const dataProject = project ? JSON.parse(project) : null;

  const getLocationDetails = async (id) => {
    try {
      const response = await getRequest(`destinations-info/${id}/1`);
      const data = response.data.data ?? [];
      const promotions = JSON.parse(data?.promotions);
      const promotionImage = promotions
        ?.map((item, i) => {
          const currentDate = new Date();
          const startDate = new Date(item.start_date);
          const endDate = item.end_date ? new Date(item.end_date) : null;

          // Normalize the dates to ignore the time component
          const normalizeDate = (date) => {
            const newDate = new Date(date);
            newDate.setHours(0, 0, 0, 0);
            return newDate;
          };

          const normalizedCurrentDate = normalizeDate(currentDate);
          const normalizedStartDate = normalizeDate(startDate);
          const normalizedEndDate = endDate ? normalizeDate(endDate) : null;

          if (
            normalizedStartDate <= normalizedCurrentDate &&
            (normalizedEndDate === null ||
              normalizedEndDate >= normalizedCurrentDate)
          ) {
            return {
              image_path: image_url + item.image_path,
              startDate: startDate,
              endDate: endDate,
            };
          }
          return null;
        })
        .filter((item) => item != null);

      let datas = {
        ...data,
        promotionImage: promotionImage,
      };
      console.log(datas, "data");
      let values = {
        from: data?.name,
        from_id: data?.enc_id,
        from_floor_plan: data?.floor_plan,
        project_id: data?.enc_project_id,
        from_floor_plan_id: data?.enc_floor_plan_id,
        customer_id: data?.enc_customer_id,
        from_draft_id: data?.draft_id,
        type: data?.type,
      };
      if (setCurrentFloor && type == "from") {
        // setCurrentFloor(data?.enc_floor_plan_id);
        // if (setFromDestinationData) {
        //     setFromDestinationData(values)
        // }
      }
      setLocationDtls(datas);
      getWeekDetails(datas);
      getPinViews(datas?.enc_id);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false)
    }
  };

  const getPinViews = async (publishedId) => {
    const formData = {
      customer_id: dataProject?.enc_customer_id,
      project_id: dataProject?.enc_id,
      type: 1,
      type_id: publishedId,
    };
    try {
      const response = await postRequest(`pin-views`, formData);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false)
    }
  };

  const getWeekDetails = (data) => {
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const weekDetails = days.map((day) => ({
      day,
      open: data[`${day.toLowerCase()}_open`],
      start: data[`${day.toLowerCase()}_start`],
      end: data[`${day.toLowerCase()}_end`],
    }));
    setHours(weekDetails);
  };

  useEffect(() => {
    if (open && id) {
      getLocationDetails(id);
    }
  }, [id, open]);

  const formatTimeToAMPM = (time) => {
    if (time) {
      const date = new Date(`2000-01-01T${time}`);
      var hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    } else {
      return `--:-- `;
    }
  };

  const SetAsdestination = () => {
    if (type == "from") {
      const dataToStore = {
        from: locationDtls?.name,
        from_id: locationDtls?.enc_id,
        from_floor_plan: locationDtls?.floor_plan,
        type: locationDtls?.type,
        from_floor_plan_id: locationDtls?.enc_floor_plan_id,
        from_type_name: "location",
        from_draft_id: locationDtls?.draft_id,
      };
      localStorage.setItem("from_location", JSON.stringify(dataToStore));
      //   setCurrentFloor();
      if (setCurrentFloor) {
        setCurrentFloor(locationDtls?.floor_plan);
      }
      navigate(`/view-floorplan/${0}`);
      setOpen(false);
      closes();
    } else {
      console.log(locationDtls, "dadadaad");
      const dataToStore = {
        to: locationDtls?.name,
        to_id: locationDtls?.enc_id,
        to_floor_plan: locationDtls?.floor_plan,
        type: locationDtls?.type,
        to_floor_plan_id: locationDtls?.enc_floor_plan_id,
        to_type_name: "location",
        to_draft_id: locationDtls?.draft_id,
      };

      localStorage.setItem("to_location", JSON.stringify(dataToStore));
      setOpen(false);
      closes();
      navigate(`/view-floorplan/${1}`);

      navigate(`/view-floorplan/${0}`);
    }
  };

  return (
    <>
      <Sheet
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setLocationDtls([]);
          setHours([]);
        }}
        detent="content-height"
        className='sheet-cont'
      >
        <div className="modal-sheet-container">
          <Sheet.Container>
            <Sheet.Content>
              <Card className="mt-1">
                <CardBody className="content-card">
                  <Row className="">
                    <Col sm={12} className="d-flex  align-end">
                      <h6 className="header mb-0">{locationDtls?.name}</h6>
                      <div className="close-icon mt-1" onClick={close}>
                        <AiOutlineClose />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <p className="mb-0 listcontent">
                        {locationDtls?.floor_plan}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={12}>
                      <Button
                        size="small"
                        key="4"
                        type="primary"
                        onClick={SetAsdestination}
                        className="btn btn-primary"
                        block
                      >
                        {" "}
                        Set as{" "}
                        {type === "from" ? "starting point" : "destination"}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <div
                className="bg-color container-fluid"
                style={{ overflow: "auto" }}
              >
                {locationDtls?.promotionImage?.length > 0 && (
                  <ImageSlider sliders={locationDtls?.promotionImage} />
                )}
                {locationDtls?.description && (
                  <Row className="mt-3">
                    <Col sm={12}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h6 className="light-heading">Description</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} className="f-14 fw-500">
                              <p>{locationDtls?.description}</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {hours.some((Open) => Open.open === 1) && (
                  <Row className="mt-3">
                    <Col sm={12}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col sm={12}>
                              <h6 className="light-heading">Hours</h6>
                            </Col>
                          </Row>
                          {hours.map((Open, index) => (
                            <Row>
                              <Col sm={12} key={index}>
                                <div className="d-flex align-items-center align-end f-14 fw-500">
                                  {Open.open === 1 && (
                                    <>
                                      <p className="mb-0">{Open.day}</p>
                                      {Open.open === 1 ? (
                                        <p className="mb-0">
                                          {formatTimeToAMPM(Open.start)} -{" "}
                                          {formatTimeToAMPM(Open.end)}
                                        </p>
                                      ) : (
                                        <p className="mb-0">Closed</p>
                                      )}
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col sm={12}>
                    {locationDtls?.contact && (
                      <Card className="mb-3">
                        <CardBody style={{ padding: "0px" }}>
                          <Row justify="center">
                            <Col sm={12}>
                              <div
                                className=""
                                style={{ padding: "15px", minHeight: "70px" }}
                              >
                                <h6 className="light-heading">Phone</h6>
                                <p className="mb-0 text-color">
                                  <a
                                    className="text-color"
                                    href={`tel:${locationDtls?.contact}`}
                                  >
                                    {locationDtls?.contact}
                                  </a>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {locationDtls?.website && (
                      <Card className="mb-3">
                        <CardBody style={{ padding: "0px" }}>
                          <Row justify="center">
                            <Col sm={12}>
                              <div
                                className=""
                                style={{ padding: "15px", minHeight: "70px" }}
                              >
                                <h6 className="light-heading">Website</h6>
                                <p className="mb-0 text-color">
                                  <a
                                    className="text-color"
                                    href={
                                      locationDtls?.website?.startsWith("http")
                                        ? locationDtls.website
                                        : `https://${locationDtls.website}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {locationDtls?.website}
                                  </a>
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>
            </Sheet.Content>
          </Sheet.Container>
        </div>
        <Sheet.Backdrop onClick={(e) => e.stopPropagation()} />
      </Sheet>
    </>
  );
};

export default SetDestination;
