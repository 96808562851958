import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Card, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Main } from "../Styles/styled";
import { fabric } from "fabric";
import { getRequest } from "../hooks/axiosClient";
import FloorsList from "./floorList";
import LogoContainer from "./LogoContainer";
import VerticalTransportModal from "./VerticalTransportModal";
import '../App.css';
import {
  addLocationPin,
  addProductPin,
  addBeaconPin,
  addAmenityPin,
  addSafetyPin,
  addVerticalPin
} from "./pins";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import Sheet from "react-modal-sheet";
import ImageSlider from "./ImageSlider";
import { escapeSpecialCharacters } from "../helpers/escapeSpecialCharacters";
import toast, { Toaster } from "react-hot-toast";
import Graph from "../helpers/CanvasConstants.js/graph";
import { ChangeSvgColorPassingBE, addApointToRotate, applyZoom, canvasBackGroundImage, fitObjectsInView, getAngleBetweenTouches, getSquareCoordinates, pinNameTextBringtoFront, reinitialiseFabric, removeFabricObjectsByName, rotateCanvas, setCanvasBackgroundImageAsimage, updateVisibleTiles } from "../helpers/CanvasConstants.js/canvasFunctions";
import { renderAmenities, renderBeacons, renderSafeties, renderTexts, renderTracingCircle, renderTracings, renderVerticalTransport } from "../helpers/CanvasConstants.js/canvasObjs";
import { dijkstra, dijkstraWithLength } from "../helpers/CanvasConstants.js/dijkstra";
import { findObjectByEnc_id, findObjectById } from "../helpers/bringFabricObjects";
import { environmentaldatas } from "../constant/defaultValues";
const { image_url } = environmentaldatas

const graph = new Graph();
let isDragging = false;
let prevPointer;
function ShowDestination() {
  let lastX,
    lastY,
    lastZoom = 1;
  const { type } = useParams();
  const [destinationData, setDestinationData] = useState();
  const [selFloorData, setSelFloorData] = useState();
  const [fromSourseData, setFromSourseData] = useState();
  const [prevFloor, setPrevFloor] = useState();
  const [nextFloor, setNextFloor] = useState();
  const [canvas, setCanvas] = useState();
  const project = localStorage.getItem("project_data");

  const [projectSettings, setProjectSettings] = useState(
    project ? JSON.parse(project) : null
  );
  const [modal, setModal] = useState(false);
  const [verticalData, setVerticalData] = useState();
  const [allVerticalData, setAllVerticalData] = useState();
  const [currentFloor, setCurrentFloor] = useState();
  const [selectedVertical, setSelectedVertical] = useState();
  const [canvasOuterPoints, setCanvasOuterPoints] = useState({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  });
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();
  const [isArrowUp, setIsArrowUp] = useState(true);
  const toggleArrowDirection = () => { setIsArrowUp((prev) => !prev); };
  const [open, setOpen] = useState(true);
  const [adDetails, setAdDetails] = useState([]);
  const [floorsListrOrder, setFloorsListrOrder] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  const [allVerticalTransports, setAllVerticalTransports] = useState([]);
  const [allPointsAndEdges, setAllPointsAndEdges] = useState([]);
  const [selTraversibleDetails, setSelTraversibleDetails] = useState();
  const istaskrendered = useRef(false)
  const [shortestPathPoints, setShortestPathPoints] = useState([]);
  const [graphPossitionPoints, setGraphPositionPoints] = useState([]);
  
  
  const [verticalPathConnectionResult, setVerticalPathConnectionResult] = useState([]);

  useEffect(()=>{
   let point = graph.getPositions()
    setGraphPositionPoints(point)
  },[selFloorData])

  // variables for canvas rotation
  let initialAngle = 0;
  let initialTouchAngle = 0;
  let animationFrameId;

  useEffect(() => {
    let animationFrameId; 
    let arrowIntervalId; 
    const arrows = []; 

    
  
    if (shortestPathPoints && shortestPathPoints.length > 0  ) {
      let nodes = graphPossitionPoints;
      let points = shortestPathPoints?.map((item) => nodes[item]);  
     
      const arrowSpeed = 2.5;
      const arrowInterval = 3000;
      const maxArrows = 10;
  
      function createArrow() {
        if (arrows.length < maxArrows) {
          const arrow = new fabric.Triangle({
            fill: projectSettings?.navigation_color ?? "red",
            left: points[0]?.x,
            top: points[0]?.y,
            height: 15 + projectSettings?.navigation_thick,
            width: 15 + projectSettings?.navigation_thick,
            customType: "navigation-arrow",
            originX: "center",
            originY: "center",
            selectable: false,
            name: "arrow-head",
            ignoreZoom: true,
            skipAbsolute: true,
            scaleX: 1 / canvas?.getZoom(),
            scaleY: 1 / canvas?.getZoom(),
          });
          canvas.add(arrow);
          arrows.push({
            object: arrow,
            currentSegment: 0,
            progress: 0,
          });

          const highlightPins = canvas.getObjects().filter(obj => obj.types === "highlight_pin");
          highlightPins.forEach((obj)=>{
            canvas.bringToFront(obj)
          })
        }
      }
  
      function clearArrows() {
        arrows.forEach((arrowData) => {
          canvas.remove(arrowData.object);
        });
        arrows.length = 0; 
      }
      createArrow()

      function animateArrows() {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
      
        const zoom = canvas?.getZoom() || 1; 
            
        arrows.forEach((arrowData) => {
          const { object: arrow } = arrowData;
      
          // Remove arrows that have reached the end of the path
          if (arrowData.currentSegment >= points.length - 1) {
            const oldArrow = arrows.shift();
            canvas.remove(oldArrow.object); // Ensure we remove the object properly
            canvas.remove(arrow); // Remove the current arrow
            return;
          }
      
          const start = points[arrowData.currentSegment];
          const end = points[arrowData.currentSegment + 1];
      
          const segmentLength = Math.sqrt(
            Math.pow(end?.x - start?.x, 2) + Math.pow(end?.y - start?.y, 2)
          );
      
          // Adjust the arrow speed based on zoom
          let adjustedArrowSpeed;
          let progressIncrement;
          if(zoom > 1){
            adjustedArrowSpeed = arrowSpeed / (zoom); 
            progressIncrement = adjustedArrowSpeed / segmentLength;
          }else{
            adjustedArrowSpeed = arrowSpeed / 1; 
            progressIncrement = adjustedArrowSpeed / segmentLength;
          }
          
          
          const x = start?.x + (end?.x - start?.x) * arrowData.progress;
          const y = start?.y + (end?.y - start?.y) * arrowData.progress;
      
          // Update arrow position and angle
          arrow.set({
            left: x,
            top: y,
            angle:
              (Math.atan2(end?.y - start?.y, end?.x - start?.x) * 180) / Math.PI + 90,
          });
          
          arrowData.progress += progressIncrement;
      
          // Move to the next segment when the current progress reaches 1
          if (arrowData.progress >= 1) {
            arrowData.progress = 0;
            arrowData.currentSegment++;
          }
        });
      
        
        // Re-render the canvas
        canvas.setZoom(canvas.getZoom());
        canvas.renderAll();
      
        // Request the next frame of the animation
        animationFrameId = requestAnimationFrame(animateArrows);
      }
  
      // Start arrow creation at intervals
      arrowIntervalId = setInterval(createArrow, arrowInterval);
      animateArrows();


      // Cleanup on unmount or dependency change
      return () => {
        // Clear existing arrows
        clearArrows();
  
        // Stop animation and interval
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
        if (arrowIntervalId) {
          clearInterval(arrowIntervalId);
        }
      };

    }
  }, [shortestPathPoints, graphPossitionPoints]);


  const removeArrows = () => {
    if(canvas){
      const arrows = canvas.getObjects().filter(obj => obj.customType === "navigation-arrow");
      if(arrows){
        arrows.forEach(arrow => canvas.remove(arrow));
        canvas.renderAll();
      }
    }
  };

  function removeDuplicates(arr) {
    const uniqueObjects = {};

    arr.forEach(obj => {
      if (!(obj.icon_id in uniqueObjects)) {
        uniqueObjects[obj.icon_id] = obj;
      } else if (obj.is_wheelchair === 1) {
        uniqueObjects[obj.icon_id] = obj;
      }
    });

    return Object.values(uniqueObjects);
  }

  const getFloorDetails = async (floorId, fromData, toData, type) => {
    setCurrentFloor(floorId); 
    let from;
    let to;
    if (fromData) {
      from = JSON.parse(fromData);
      if (!type) {
        setDestinationData(from);
        setFromSourseData(from);
      } else if (type === "prev") {
        setFromSourseData(prevFloor?.from);
      }
    }
    if (toData) {
      to = JSON.parse(toData);
      if (!type) {
        setDestinationData(to);
      }
    }
    if (fromData && toData && !type) {
      const traversableData = {
        from: `${from.from_type_name}_${from.from_draft_id}`,
        to: `${to.to_type_name}_${to.to_draft_id}`,
        from_floor_id: from?.from_floor_plan_id,
        to_floor_id: to?.to_floor_plan_id,
        from_draft_id: from.from_draft_id,
        to_draft_id: to.to_draft_id,
        from_id: from?.from_id,
        to_id: to?.to_id,
        from_type_name: from?.from_type_name,
        to_type_name: to?.to_type_name
      };
      setSelTraversibleDetails(traversableData);
    }
    try {
      const response = await getRequest(`floor-data/${floorId}`);
      const data = response.data.floor_data ?? {};
      setSelFloorData(data);
      const tracings = data?.tracings ? JSON.parse(data?.tracings ?? []) : [];
      const tracingCircle = data?.circle_data ? JSON.parse(data?.circle_data ?? []) : [];
      const texts = data?.text ? JSON.parse(data?.text ?? []) : [];
      const floorImage = data?.cropped_path ? image_url + data?.cropped_path : null
      //  canvasBackGroundImage(canvas, floorImage, data)

      const locations = data?.location_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const products = data?.product_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const beacons = data?.beacon_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const amenities = data?.amenity_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const safeties = data?.safeties_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const vercalTransports = data?.vertical_transports.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));

      setAllVerticalData(vercalTransports);
      const filterVT = removeDuplicates(vercalTransports);
      setVerticalData(filterVT);
      reinitialiseFabric(canvas);
      renderTracingCircle(canvas, projectSettings, (tracingCircle ?? []));
      renderTracings(canvas, projectSettings, (tracings ?? []));

      canvasBackGroundImage(canvas, floorImage, data, istaskrendered)
      console.log(floorImage,"floorImage jnfnjfnerjfejnk");
      handleImageLoad(floorImage, data)
      setProjectData(data)
      if(istaskrendered.current) { 
        renderTexts(canvas, (texts ?? []));
        renderLocations(locations ?? [], from, to);
        if (from?.type == 2 || to?.type == 2) {
          renderProducts(products ?? [], from, to);
        }
        renderBeacons((beacons ?? []), projectSettings, canvas);
        renderAmenities((amenities ?? []), canvas, projectSettings);
        renderSafeties((safeties ?? []), canvas, projectSettings);
        renderVerticalTransport((vercalTransports ?? []), canvas, projectSettings);
        handleTraversibleData(data);
        handleCanvasContentSize();
  
        if (from?.from_floor_plan === to?.to_floor_plan) {
          showPath(from, to);
        } else {
          // if(JSON.parse(fromData).from_id){
          //   console.log(JSON.parse(fromData),`${JSON.parse(fromData).from_type_name}_${JSON.parse(fromData).from_draft_id}`,"objectfrom")
          // }
          let point = `${JSON.parse(fromData).from_type_name}_${JSON.parse(fromData).from_draft_id}`
          dragToPoint(graph.getPositions()[point])
          toggle();
        }
      }

      // setTimeout(() => {
        // istaskrendered.current = true
      // }, 1000); 
    } catch (error) {
      ////console.log(error);
    } finally {
      // setLoading(false)
    }
  };
  
  const handleCanvasContentSize = () => {
    let left;
    let right;
    let top;
    let bottom;
    canvas.getObjects().forEach((b) => {
      let a = b.getBoundingRect();
      if (left == undefined || a.left < left) {
        left = a.left;
      }
      if (top == undefined || a.top < top) {
        top = a.top;
      }
      if (right == undefined || a.left + a.width > right) {
        right = a.left + a.width;
      }
      if (bottom == undefined || a.top + a.height > bottom) {
        bottom = a.top + a.height;
      }
    });
    setCanvasOuterPoints({ top, left, bottom, right });
  };

  const renderLocations = (locations, from, to) => {
    locations?.forEach((loc, idx) => {
      let vertices;
      if (loc.boundary_attributes && loc.boundary_attributes != "null") {
        vertices = JSON.parse(loc.boundary_attributes);
      } else if (loc?.boundary_attributes === "null" && loc?.boundary_color) {
        vertices = getSquareCoordinates(loc?.position?.x, loc?.position?.y, 50);
      }
      let fillColor =
        projectSettings?.inactive_color ??
        loc?.location_color ??
        projectSettings?.location_color;
      let singleLineLocationName = escapeSpecialCharacters(loc.location_name);

      let textObj = new fabric.Text(loc.location_name, {
        left: loc.position?.x,
        top: loc.position?.y - 25,
        fill: "#646464",
        fontSize: 12,
        name: "location",
        perPixelTargetFind: true,
        position: "absolute",
        zIndex: 2000,
        selectable: false,
        originX: "center",
        originY: "center",
        fontFamily: `Arial`,
        ignoreZoom: true,
        skipAbsolute: true,
        initialTop: loc.position?.y - 25,
        initialLeft: loc.position?.x,
        initialZoom: 1
      });
      let locationIcon = `<svg width="${textObj.width + 5 + 5
        }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">

        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m10,0c-5.53061,0 -10,4.32465 -10,9.67615c0,1.56005 0.42857,3.08055 1.08163,4.44315c0.46939,0.9873 1.55102,2.4684 1.81633,2.8238c1.53061,2.0142 7.10204,8.0569 7.10204,8.0569c0,0 5.5918,-6.0427 7.102,-8.0569c0.2653,-0.3554 1.347,-1.8167 1.8164,-2.8238c0.653,-1.3626 1.0816,-2.8831 1.0816,-4.44315c0,-5.3515 -4.4694,-9.67615 -10,-9.67615z"/>
         <path id="svg_2" fill="white" d="m9.99998,17.7923c4.63242,0 8.38772,-3.6337 8.38772,-8.11613c0,-4.4824 -3.7553,-8.11611 -8.38772,-8.11611c-4.63242,0 -8.38775,3.63371 -8.38775,8.11611c0,4.48243 3.75533,8.11613 8.38775,8.11613z"/>
         <path id="svg_3" fill="${fillColor}" d="m10,16.331c3.7984,0 6.8776,-2.9795 6.8776,-6.65486c0,-3.67535 -3.0792,-6.65482 -6.8776,-6.65482c-3.79836,0 -6.87755,2.97947 -6.87755,6.65482c0,3.67536 3.07919,6.65486 6.87755,6.65486z"/>
         <path id="svg_4" fill="white" d="m13.1429,7.80014c1.2623,0 2.2857,-0.99021 2.2857,-2.21169c0,-1.22148 -1.0234,-2.2117 -2.2857,-2.2117c-1.2624,0 -2.2858,0.99022 -2.2858,2.2117c0,1.22148 1.0234,2.21169 2.2858,2.21169z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${textObj.width + 5
        }" height="20" rx="2" fill="#ffffffad" stroke="transparent" stroke-width="1"/>
       
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineLocationName}</text>
      </g>
       </svg>`;

      let square;
      let path = fabric.loadSVGFromString(
        locationIcon,
        function (objects, options) {
          let obj = fabric.util.groupSVGElements(objects, options);
          let currentZoom = canvas.getZoom();
          let adjustedWidth = obj.width * (1 / currentZoom);
          let adjustedHeight = obj.height * (1 / currentZoom);
          let backgroundRect = new fabric.Rect({
            left: loc.position?.x,
            top: loc.position?.y - 25,
            width: textObj.width + 10,
            height: textObj.height + 5,
            fill: "#ffffffad",
            stroke: "transparent",
            strokeWidth: 1,
            originX: "center",
            originY: "center",
            selectable: false,
            name: "location",
            rx: 2,
            ry: 2,
            ignoreZoom: true,
            skipAbsolute: true,
            initialTop: loc.position?.y - 25,
            initialLeft: loc.position?.x,
            initialZoom: 1
          });
          let group = new fabric.Group([backgroundRect, textObj], {
            name: "location",
            selectable: false,
            ignoreZoom: true,
            skipAbsolute: true,
            centeredScaling: true

          });
          obj.set({
            id: loc.location_name,
            left: loc.position?.x - obj?.width / 2,
            top: loc.position?.y - obj?.height / 2,
            selectable: false,
            name: "location",
            enc_id: loc?.enc_id,
            uniformScaling: true,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            isBoundary: loc?.boundary_attributes ? true : false,
            draft_id: loc?.location_id,
            position: loc?.position,
            ignoreZoom: true,
            skipAbsolute: true,
            boundary_color: loc?.boundary_color ?? null,
            boundary_attributes: vertices ?? null,
            color: loc?.location_color
          });

          canvas.add(obj).renderAll();
          canvas.bringToFront(obj);
        }
      );
    });
  };

  const renderProducts = (products, from, to) => {
    products.forEach((prod, idx) => {
      let fillColor =
        "#b2b2b2" ?? prod?.product_color ?? projectSettings?.product_color;
      let singleLineProductName = escapeSpecialCharacters(prod.product_name);
      let textObj = new fabric.Text(prod.product_name, {
        left: prod.position?.x,
        top: prod.position?.y - 25,
        fill: "#646464",
        fontSize: 12,
        name: "product",
        perPixelTargetFind: true,
        position: "absolute",
        zIndex: 2000,
        selectable: false,
        originX: "center",
        originY: "center",
        fontFamily: `Arial`,
        initialTop: prod.position?.y - 25,
        initialLeft: prod.position?.x,
        initialZoom: 1
      });

      let productIcon = `<svg width="${textObj.width + 5 + 5
        }" height="25" xmlns="http://www.w3.org/2000/svg" fill="none">
        <g transform="translate(${textObj.width / 2 - 5}, 0)">
         <title>Layer 1</title>
         <path id="svg_1" fill="${fillColor}" d="m10,0c-5.52783,0 -10,4.32764 -10,9.67682c0,1.56018 0.42227,3.08318 1.07486,4.43908c0.47985,1.003 1.5547,2.4703 1.82341,2.8232c1.51632,2.0245 7.10173,8.0609 7.10173,8.0609c0,0 5.5854,-6.0364 7.1017,-8.0609c0.2687,-0.3529 1.3436,-1.8202 1.8234,-2.8232c0.6526,-1.3559 1.0749,-2.8789 1.0749,-4.43908c0,-5.34918 -4.4722,-9.67682 -10,-9.67682z"/>
         <path id="svg_2" fill="white" d="m9.99999,17.7935c4.63241,0 8.38771,-3.634 8.38771,-8.11669c0,-4.48269 -3.7553,-8.11664 -8.38771,-8.11664c-4.63241,0 -8.38772,3.63395 -8.38772,8.11664c0,4.48269 3.75531,8.11669 8.38772,8.11669z"/>
         <path id="svg_3" fill="${fillColor}" d="m10,16.3262c3.795,0 6.8714,-2.9771 6.8714,-6.64938c0,-3.67232 -3.0764,-6.64933 -6.8714,-6.64933c-3.79497,0 -6.8714,2.97701 -6.8714,6.64933c0,3.67228 3.07643,6.64938 6.8714,6.64938z"/>
         <path id="svg_4" fill="white" d="m10,11.9057c1.2721,0 2.3033,-0.9979 2.3033,-2.22888c0,-1.23094 -1.0312,-2.22882 -2.3033,-2.22882c-1.27206,0 -2.30327,0.99788 -2.30327,2.22882c0,1.23098 1.03121,2.22888 2.30327,2.22888z"/>
        </g>
        <g>
        <!-- Rectangle with border -->
        <rect x="5" y="-26" width="${textObj.width + 5
        }" height="20" rx="2" fill="#ffffffad" stroke="transparent" stroke-width="1"/>
       
        <!-- Text -->
        <text x="7" y="-12" font-family="Arial" font-size="12" fill="#646464">${singleLineProductName}</text>
      </g>
       </svg>`;

      let path = fabric.loadSVGFromString(
        productIcon,
        function (objects, options) {
          let obj = fabric.util.groupSVGElements(objects, options);

          let backgroundRect = new fabric.Rect({
            ignoreZoom: true,
            left: prod.position?.x,
            top: prod.position?.y - 25,
            width: textObj.width + 12,
            height: textObj.height + 5,
            fill: "#ffffffad",
            strokeWidth: 1,
            originX: "center",
            originY: "center",
            selectable: false,
            name: "product",
            rx: 2,
            ry: 2,

            initialTop: prod.position?.y - 25,
            initialLeft: prod.position?.x,
            initialZoom: 1
          });
          let group = new fabric.Group([backgroundRect, textObj], {
            name: "product",
            selectable: false,
            centeredScaling: true
          });

          obj.set({
            left: prod.position?.x - obj.width / 2,
            top: prod.position?.y - obj.height / 2,
            selectable: false,
            name: "product",
            id: prod.product_name,
            enc_id: prod?.enc_id,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            draft_id: prod?.product_id,
            position: prod?.position,
            color: prod?.product_color
          });
          if (
            from?.from_draft_id == prod?.product_id ||
            to?.to_draft_id == prod?.product_id
          ) {
            canvas.add(obj).renderAll();
            canvas.bringToFront(obj);
          }
        }
      );
    });
  };

  const handleTraversibleData = (floorPlanDtls) => {
    // if (floorPlanDtls.edges_data) {
    //   const edges = JSON.parse(floorPlanDtls.edges_data);
    //   if (edges?.length === 0) {
    //     graph.restoreEdges();
    //   } else {
    //     graph.restoreEdges(edges);
    //     const nodeFromAPI = Object.keys(edges);
    //     nodeFromAPI?.forEach((n) => {
    //       graph.addNode(n);
    //     });
    //   }
    // } else {
    //   graph.restoreEdges();
    // }
    // if (floorPlanDtls.points_data) {
    //   const points = JSON.parse(floorPlanDtls.points_data);
    //   if (points?.length === 0) {
    //     graph.restorePositions();
    //   } else {
    //     graph.restorePositions(points);
    //   }
    // } else {
    //   graph.restorePositions();
    // }


    if (floorPlanDtls.edges_data) {
      const edges = JSON.parse(floorPlanDtls.edges_data);
      if (edges?.length === 0) {
        graph.restoreEdges();
        graph.restoreNodes();
        graph.restoreHighlightNode();
      } else {
        graph.restoreEdges(edges);
        const nodeFromAPI = Object.keys(edges);
        nodeFromAPI?.forEach((n) => {
            graph.addNode(n);
        });
      }
    } else {
      graph.restoreEdges();
      graph.restoreNodes()
      graph.restoreHighlightNode()
    } 
    if (floorPlanDtls.points_data) {
      const points = JSON.parse(floorPlanDtls.points_data);
      if (points?.length === 0) {
          graph.restorePositions();
      } else {
          graph.restorePositions(points);
      }
    } else {
      graph.restorePositions();
    }
    if (floorPlanDtls.highlight_data) {
      const node = JSON.parse(floorPlanDtls.highlight_data);
      if (node?.length === 0) {
          graph.restoreHighlightNode();
      } else {
          graph.restoreHighlightNode(node);
      }
    } else {
      graph.restoreHighlightNode();
    }

      if (floorPlanDtls.sub_node) {
        const node = JSON.parse(floorPlanDtls.sub_node);
        if (node?.length === 0) {
          graph.restoreSubnode();
        } else {
          graph.restoreSubnode(node);
        }
      } else {
        graph.restoreSubnode();
      }
  
    if (floorPlanDtls.connected_nodes) {
      const node = JSON.parse(floorPlanDtls.connected_nodes);
      if (node?.length === 0) {
        graph.restoreConnectedMainPathNodes();
      } else {
        graph.restoreConnectedMainPathNodes(node);
      }
    } else {
      graph.restoreConnectedMainPathNodes();
    }
  };

  window.addEventListener('resize', () => {
    if (canvas) {
      const height = window.innerHeight;
      const width = window.innerWidth;
      canvas.setHeight(height);
      canvas.setWidth(width);
      canvas.renderAll();
    }
  });

  const initialiseFabric = () => {
    let isDragging = false;
    let lastX = 0;
    let lastY = 0;

    let isZooming = false;
    let initialPinchDistance = 0;
    let pinchCenterX = 0;
    let pinchCenterY = 0;

    let zoomStartScale;
    let pausePanning = false;
    let currentX
    let currentY
    let xChange
    let yChange

    const existingCanvas = document.getElementById("canvas");
    if (existingCanvas) {
      existingCanvas.innerHTML = ""; // Clear any existing canvas elements
    }

    let oldcanvas

    setCanvas((prev)=>{
      oldcanvas = prev
      return prev
    })


    // console.log(oldcanvas,existingCanvas,"oldcanvasoldcanvasoldcanvasoldcanvas");
    if (oldcanvas) {
      oldcanvas.dispose(); 
    }

    const canvas = new fabric.Canvas("canvas", {
      height: window.innerHeight,
      width: window.innerWidth,
      // backgroundColor: projectSettings?.background_color
      //     ? hexToRgb(projectSettings?.background_color)
      //     : "#F6F7F3",
      backgroundColor: projectSettings?.background_color ?? "#F6F7F3",
      preserveObjectStacking: true,
      allowTouchScrolling: false
    });

    canvas.requestRenderAll();

    canvas.on("mouse:wheel", function (options) {
      const delta = options.e.deltaY;
      // const zoom = canvas.getZoom() * (1 + delta / 1000);
      var zoom = canvas.getZoom();
      if (delta > 0) {
        zoom /= 1.1;
      } else {
        zoom *= 1.1;
      }
      // console.log(zoom,"zoooom")
      applyZoom(canvas, options.e.offsetX, options.e.offsetY, zoom, "mouse");
      options.e.preventDefault();
      options.e.stopPropagation();
      updateVisibleTiles(canvas,tilesRef)
    });

    canvas.on("mouse:down", function (opt) {
      isDragging = true;
      canvas.selection = false;
      // console.log(opt.target);
      // pauseAnimation()

      if (opt.e.type === "touchstart") {

        if (opt.e.touches.length === 1) {
          lastX = opt.e.touches[0].clientX;
          lastY = opt.e.touches[0].clientY;
        } else if (opt.e.touches.length === 2) {
          const centerX =
            (opt.e.touches[0].clientX + opt.e.touches[1].clientX) / 2;
          const centerY =
            (opt.e.touches[0].clientY + opt.e.touches[1].clientY) / 2;
          pinchCenterX = centerX;
          pinchCenterY = centerY;

          const deltaX = opt.e.touches[0].clientX - opt.e.touches[1].clientX;
          const deltaY = opt.e.touches[0].clientY - opt.e.touches[1].clientY;
          // initialPinchDistance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
          initialPinchDistance = Math.hypot(deltaX, deltaY);
        }
      }
      if (opt.e.button === 0) { // 0 is for the left mouse button
        isDragging = true;
        lastX = opt.e.clientX;
        lastY = opt.e.clientY;
        canvas.selection = false;
      }
    });


    // canvas.on('object:removed', (e) => {
    //   const removedObject = e.target;
    //   console.log(`An object was removed from the canvas:`, removedObject);
    // });


    canvas.on("mouse:move", throttle(function (evt) {
      if (evt.e.touches && evt.e.touches.length === 2) {
        isZooming = true;
        const currentPinchDistance = Math.hypot(
          evt.e.touches[0].clientX - evt.e.touches[1].clientX,
          evt.e.touches[0].clientY - evt.e.touches[1].clientY
        );
        const delta = currentPinchDistance - initialPinchDistance;
        var zoom = canvas.getZoom() * (1 + delta / 1000);
        // var zoom = canvas.getZoom();
        if (delta > 0) {
          // zoom += (delta * 0.1);
          zoom += 0.2;
        } else {
          // zoom -= (delta * 0.1);
          zoom -= 0.2;
        }
        const centerX =
          (evt.e.touches[0].clientX + evt.e.touches[1].clientX) / 2;
        const centerY =
          (evt.e.touches[0].clientY + evt.e.touches[1].clientY) / 2;
        pinchCenterX = centerX;
        pinchCenterY = centerY;
      }

      if (isDragging && !isZooming) {
        if (evt.e.type === "mousemove") {
          const delta = new fabric.Point(evt.e.clientX - lastX, evt.e.clientY - lastY);
          canvas.relativePan(delta);
          lastX = evt.e.clientX;
          lastY = evt.e.clientY;
          // const delta = new fabric.Point(evt.e.movementX, evt.e.movementY);
          // var pointer = canvas.getPointer(evt.e);
          // const viewBoundary = canvas.calcViewportBoundaries();
          // let boundPoints = {};
          // setCanvasOuterPoints((prev) => {
          //   boundPoints = prev;
          //   return prev;
          // });
          // if (
          //   (viewBoundary.tl.x >= boundPoints.left &&
          //     prevPointer?.x > pointer.x) ||
          //   (viewBoundary.tr.x <= boundPoints.right &&
          //     prevPointer?.x < pointer.x) ||
          //   (viewBoundary.bl.y > boundPoints.bottom &&
          //     prevPointer?.y > pointer.y)
          // ) {
          //   return;
          // }
          // canvas.relativePan(delta);
          // prevPointer = pointer;
        } else if (evt.e.type === "touchmove" && evt.e.touches.length === 1) {
          const deltaX = evt.e.touches[0].clientX - lastX;
          const deltaY = evt.e.touches[0].clientY - lastY;
          const delta = new fabric.Point(deltaX, deltaY);
          canvas.relativePan(delta);
          lastX = evt.e.touches[0].clientX;
          lastY = evt.e.touches[0].clientY;
        }
      }
      updateVisibleTiles(canvas,tilesRef)
      return;
    }, 33));

    canvas.on("mouse:up", function () {
      isDragging = false;
      isZooming = false;
      initialPinchDistance = 0;
      // updateVisibleTiles(canvas,tilesRef)
      // resumeAnimation()
    });
    setCanvas(canvas);

    function applyPinchZoom(centerX, centerY, zoom, type) {
      console.log('pinchzoom')
      canvas.forEachObject((obj) => {
        const center = obj.getCenterPoint();

        if (
          obj.name != "short_path" &&
          obj.name != "svg_refImage" &&
          // obj.name != "arrow"
          // &&
          obj.name != "tracing" &&
          obj.name != "boundary" &&
          obj.name != "text"
        ) {
          if (obj.type == "group" && !obj?.id && obj.name != "text") {
            obj.forEachObject((obj1) => {
              obj1.set({
                ignoreZoom: true,
                skipAbsolute: true,
                scaleX: 1 / canvas?.getZoom(),
                scaleY: 1 / canvas?.getZoom(),
                strokeWidth: 1
              });
            });
          }
          else {
            obj.set({
              ignoreZoom: true,
              skipAbsolute: true,
              scaleX: 1 / canvas?.getZoom(),
              scaleY: 1 / canvas?.getZoom()
            });
          }
          obj.setPositionByOrigin(center, "center", "center");

          canvas.requestRenderAll();
        }
        // else if (obj.name == "short_path") {
        //     const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
        //     let strokeWidth = originalStrokeWidth / canvas?.getZoom();
        //     strokeWidth = Math.max(Math.min(strokeWidth, originalStrokeWidth), 0.3);
        //     obj.set({ strokeWidth: strokeWidth });
        // }
        else if (obj.name == "short_path") {
          const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
          const initialZoom = obj.initialZoom || 1; // Fallback to 1 if initial zoom is not set
          const currentZoom = canvas.getZoom();
          const strokeWidth = originalStrokeWidth * (initialZoom / currentZoom);
          obj.set({
            strokeWidth: strokeWidth,
            ignoreZoom: true,
            skipAbsolute: true,
          });
          obj.setPositionByOrigin(center, "center", "center");

        }

        /* Adjust opacity of pins in zoom out */
        if (obj?.name != "tracing" &&
          obj.name != "svg_refImage" &&
          obj?.types != 'highlight_pin' &&
          obj?.name != "short_path" &&
          obj?.name != "text" &&
          obj?.name != "safety" &&
          obj?.name != "amenity" &&
          obj?.name != "vertical" &&
          obj?.name != "boundary" &&
          obj?.name != "arrow-head" &&
          canvas.getZoom() <= 0.35
        ) {

          if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
            // console.log(zoom, '0.6')
            obj.set({ opacity: 0.6 })
          } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
            obj.set({ opacity: 0.3 })
            // console.log(zoom, '0.3')
          } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
            obj.set({ opacity: 0.1 })
            // console.log(zoom, '0.1')
          } else if (canvas.getZoom() < 0.1) {
            // console.log(zoom, '0')
            obj.set({ opacity: 0 })
          }
          // if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
          //     console.log(zoom, '0.5')
          //     obj.set({ opacity: 0.5 })
          // } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
          //     obj.set({ opacity: 0.2 })
          //     console.log(zoom, '0.2')
          // } else if (canvas.getZoom() < 0.2) {
          //     console.log(zoom, '0')
          //     obj.set({ opacity: 0 })
          // }


        } else if (obj?.name != "boundary") {
          obj.set({ opacity: 1 })
        }

        canvas.requestRenderAll();

      });

    }

    let initialDistance = null;
    let zoom = canvas.getZoom();

    const transformToCanvasCoordinates = (point, canvas) => {
      const transform = canvas.viewportTransform;
      const zoom = canvas.getZoom();
      return {
        x: (point.x - transform[4]) / zoom,
        y: (point.y - transform[5]) / zoom
      };
    };

    const handleTouchMove = (e) => {
      // console.log(zoom,canvas.getZoom());
     
      if (e.touches.length === 1) {
        addApointToRotate(canvas)
      }
      if (e.touches.length === 2) {
        removeArrows()
        zoom = canvas.getZoom();
        applyPinchZoom(); // Assuming this is a defined function elsewhere
        e.preventDefault(); // Prevent default actions like scrolling
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];

        const midpoint = {
          x: (touch1.clientX + touch2.clientX) / 2,
          y: (touch1.clientY + touch2.clientY) / 2
        };

        const newDistance = getDistance(touch1, touch2);
        if (initialDistance) {
          // Determine zoom direction and adjust the exponent accordingly
          const isZoomingOut = newDistance < initialDistance;
          const exponent = isZoomingOut ? 0.1 : 0.05; // Use smaller exponent for zoom out, larger for zoom in
          // Calculate new zoom based on exponential scale adjustment
          const scaleMultiplier = Math.pow(newDistance / initialDistance, exponent);
          let newZoom = zoom * scaleMultiplier;
          // Restrict zoom level to avoid excessive zoom  
          if (newZoom > 20) {
            newZoom = 20;
          } else if (newZoom < 0.05) {
            newZoom = 0.05;
          }
          // Apply the new zoom centered on the midpoint of the touch points
          canvas.zoomToPoint(new fabric.Point(midpoint.x, midpoint.y), newZoom);
          zoom = newZoom; // Update current zoom level


        }
        // canvas rotation functions
        const point1 = transformToCanvasCoordinates({ x: touch1.clientX, y: touch1.clientY }, canvas);
        const point2 = transformToCanvasCoordinates({ x: touch2.clientX, y: touch2.clientY }, canvas);

        const fixedPoint = {
          x: (point1.x + point2.x) / 2,
          y: (point1.y + point2.y) / 2
        };

        const newAngle = getAngleBetweenTouches(e.touches);
        const angleDiff = newAngle - initialAngle;

        addApointToRotate(canvas, fixedPoint)
        if(istaskrendered.current){
          let radians = fabric.util.degreesToRadians(angleDiff);
          let projectpath
          setProjectData((prev)=>{
            projectpath = prev
            return prev
          })
          if(tilesRef.current.length > 0 && projectpath?.cropped_path || tilesRef.current === "no-image"){
            setGraphPositionPoints((prev) => {
              const updatedPoints = Object.fromEntries(
                Object.entries(prev).map(([key, val]) => {
                  const pointOrigin = new fabric.Point(val.x, val.y);
                  const newPointLoc = fabric.util.rotatePoint(pointOrigin, fixedPoint, radians);
                  return [key, { x: newPointLoc.x, y: newPointLoc.y }];
                })
              );
              return updatedPoints;
            });
            
           

            rotateCanvas(angleDiff, canvas, fixedPoint,tilesRef);
          }
        }

        initialAngle = newAngle;
        // -----------------------

      }
      updateVisibleTiles(canvas,tilesRef)
      // canvas.setWidth(window.innerWidth);
      // canvas.setHeight(window.innerHeight);
      // canvas.calcOffset();
      e.preventDefault();
    };

    const handleTouchEnd = (e) => {
      if (e.touches.length < 2) {
        initialDistance = null;
        zoom = canvas.getZoom(); // Update zoom to final value
        // canvas rotation functions
        initialTouchAngle = 0;
        initialAngle = 0;
        // -----------------------
      }
    };

    const handleTouchStart = (e) => {
      if (e.touches.length === 2) {
        initialDistance = getDistance(e.touches[0], e.touches[1]);
        initialAngle = getAngleBetweenTouches(e.touches);
      }
    };

    function getDistance(touch1, touch2) {
      return Math.sqrt((touch1.clientX - touch2.clientX) ** 2 + (touch1.clientY - touch2.clientY) ** 2);
    }

    canvas.upperCanvasEl.addEventListener('touchstart', handleTouchStart, { passive: false });
    canvas.upperCanvasEl.addEventListener('touchmove', handleTouchMove, { passive: false });
    canvas.upperCanvasEl.addEventListener('touchend', handleTouchEnd);
  };

  // useEffect(() => {
  //   console.log(canvas,"dfnkjsbfsdbfkdb");
  // },[canvas])

  function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  const removeFabricObjectsId = (id) => {
    canvas?.forEachObject(function (obj) {
      if (obj.id == id) {
        canvas.remove(obj);
      }
    });
  };

  const bringFabricObjectsToFrontByDraftId = (id) => {
    canvas.forEachObject(function (obj) {
      if (obj.draft_id == id) {
        canvas.bringToFront(obj);
      }
    });
  };

  const bringObjectsToFrontByName = (name) => {
    canvas.forEachObject(function (obj) {
      if (obj.name == name) {
        canvas.bringToFront(obj);
      }
    });
  };

  const checkToItem = (item) => {
    let id =
      item?.type == 1
        ? item?.location_id
        : item?.type == 2
          ? item?.product_id
          : item?.type == 3
            ? item?.beacon_id
            : item?.type == 4
              ? item?.amenity_id
              : item?.type == 5
                ? item?.safety_id
                : item?.type == 6
                  ? item?.vtd_id
                  : undefined;
    return id;
  };

  // const setZoom = (shortestPath) => {
  //   let nodes = graph.getPositions();
  //   let startPoint = {
  //     x: nodes[shortestPath[0]].x,
  //     y: nodes[shortestPath[0]].y
  //   };
  //   let endPoint = {
  //     x: nodes[shortestPath[shortestPath.length - 1]].x,
  //     y: nodes[shortestPath[shortestPath.length - 1]].y
  //   };
  //   //console.log("herer");

  //   //console.log(startPoint, endPoint, "zp");
  //   const horizontalWidth = Math.abs(startPoint.x - endPoint.x) + 100;
  //   const verticalHeight = Math.abs(startPoint.y - endPoint.y) + 100;
  //   const screehHeight = 500;
  //   const screenWidth = window.innerWidth;
  //   let zoom = Math.min(
  //     screehHeight / verticalHeight,
  //     screenWidth / horizontalWidth
  //   );
  //   //console.log(zoom);
  //   const centerPoint = calculateCenter(
  //     [startPoint.x, startPoint.y],
  //     [endPoint.x, endPoint.y]
  //   );
  //   //console.log(centerPoint, "centerPoint");
  //   canvas.zoomToPoint(centerPoint, zoom);
  // };
  function getPointOnLine(x1, y1, x2, y2, distance) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const length = Math.sqrt(deltaX ** 2 + deltaY ** 2);

    // Calculate the parameter 't'
    const t = distance / length;

    // Calculate the coordinates of the point at the given distance
    const startX = x1 + t * deltaX;
    const startY = y1 + t * deltaY;

    const endX = x2 - t * deltaX;
    const endY = y2 - t * deltaY;

    return { start: { x: startX, y: startY }, end: { x: endX, y: endY } };
  }

  const dragToPoint = (point) => {
    canvas.setZoom(1); // reset zoom so pan actions work as expected
    let vpw = canvas.width / 1;
    let vph = canvas.height / 1;
    let x = point.x - vpw / 2; // x is the location where the top left of the viewport should be
    let y = point.y - vph / 2; // y idem
    canvas.absolutePan({ x, y });
    canvas.setZoom(1);
  };

  const setZoom = () => {
    let canvasObjects = canvas.getObjects();
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    canvasObjects.forEach((object) => {
      minX = Math.min(minX, object.left);
      minY = Math.min(minY, object.top);
      maxX = Math.max(maxX, object.left + object.width);
      maxY = Math.max(maxY, object.top + object.height);
    });
    const padding = 50;
    minX -= padding;
    minY -= padding;
    maxX += padding;
    maxY += padding;
    const boundingBoxWidth = maxX - minX;
    const boundingBoxHeight = maxY - minY;
    const screenHeight = window.innerHeight - 200;
    const screenWidth = window.innerWidth;
    const zoom = Math.min(
      screenHeight / boundingBoxHeight,
      screenWidth / boundingBoxWidth
    );
    const boundingBoxCenter = {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2
    };
    const screenCenter = {
      x: screenWidth / 2,
      y: screenHeight / 2
    };
    const translation = {
      x: (screenCenter.x - boundingBoxCenter.x) * zoom,
      y: (screenCenter.y - boundingBoxCenter.y + padding / 2) * zoom // Adjusted for padding and additional top offset
    };
    const centerPoint = {
      x: boundingBoxCenter.x * zoom + translation.x,
      y: boundingBoxCenter.y * zoom + translation.y
    };

    canvas.zoomToPoint(centerPoint, zoom);
  };

  function calculateCenter(topLeft, bottomRight) {
    const centerX = (topLeft[0] + bottomRight[0]) / 2;
    const centerY = (topLeft[1] + bottomRight[1]) / 2;
    return { x: centerX, y: centerY };
  }

  const highlightStartAndEndPoints = (fromData, toData) => {
    // console.log(fromData, toData,"fromData, toData");
    let fromArrow;
    let endArrow;
    let fromPin;
    let endPin;
    canvas.forEachObject(function (obj) {
      /* From pin */
      if (obj.located == "from") {
        fromArrow = obj;
      }
      if (obj.located == "end") {
        endArrow = obj;
      }
      if (fromData?.type != 6) {
        if (
          obj.enc_id == fromData.from_id &&
          fromData?.from_type_name == obj.name
        ) {
          fromPinRender(obj, "from");
          fromPin = obj;
        }
      } else {
        if (
          obj.draft_id == fromData.from_draft_id &&
          fromData?.from_type_name == obj.name
        ) {
          fromPinRender(obj, "from");
          fromPin = obj;
        }
      }

      /* To pin */
      if (toData?.type != 6) {
        if (obj.enc_id == toData.to_id && toData?.to_type_name == obj.name) {
          fromPinRender(obj, "to");
          endPin = obj;
        }
      } else {
        if (
          obj.draft_id == toData.to_draft_id &&
          toData?.to_type_name == obj.name
        ) {
          fromPinRender(obj, "to");
          endPin = obj;
        } else if (obj.enc_id == toData.enc_id) {
          removeFabricObjectsId(obj.id);
          obj.showTextBox = true;
          fromPinRender(obj, "to");
          endPin = obj;
        }
      }
    });

    // console.log(fromArrow,endArrow,fromPin,endPin,"fromArrow,endArrow,fromPin,endPin");

    // removeFabricObjectsId(fromPin.id)
    // removeFabricObjectsId(fromArrow.id)
    // removeFabricObjectsId(endArrow.id)
    // let group1 = new fabric.Group([endPin, endArrow], {
    //   name: "pinArrowGroup",
    //   selectable: false,
    //   ignoreZoom: true,
    //   skipAbsolute: true,
    //   centeredScaling: true
    // });
    // let group2 = new fabric.Group([fromPin, fromArrow], {
    //   name: "pinArrowGroup",
    //   selectable: false,
    //   ignoreZoom: true,
    //   skipAbsolute: true,
    //   centeredScaling: true
    // });
    // canvas?.add(group1);
    // canvas?.add(group2);
    //     let center = endPin.getCenterPoint()
    //     endPin.id='endPindddfdf'
    // endPin.addWithUpdate(endArrow)
    // endPin.setPositionByOrigin(center, "center", "center")
    // removeFabricObjectsId(endPin.id)

    canvas.renderAll();
  };

  const changeObjectColorById = (object, newColor, verticalName) => {
    if (object) {
      // removeHighlightObj('highlight_pin')
      object.set({ types: "highlight_pin" });
      object.set({ highLightColor: newColor });
      // Checking if the object is a path or a group of paths
      if (object.type === "path") {
        object.set({ fill: newColor });
      } else if (object.type === "group") {
        object.getObjects().forEach((subObj) => {
          if (subObj.type === "path" && subObj.fill === object?.initialColor) {
            subObj.set({ fill: newColor });
          }
          if (subObj.type === "rect" && subObj.stroke === "transparent") {
            subObj.set({ stroke: newColor });
          }
        });
      }

      let hasRect = false;
      object._objects.forEach((obj) => {
        if (obj.type === "rect") {
          hasRect = true;
        }
      });

      // console.log(verticalName, "verticalName");
      // If no <rect> element found, append the rectangle and text
      if (!hasRect && verticalName) {
        const text = new fabric.Text(verticalName, {
          left: 0,
          top: -23,
          fontFamily: "Arial",
          fontSize: 12,
          fill: "#646464",
          originX: "center",
          originY: "center"
        });
        // console.log(text.width, "wieee");
        const rect = new fabric.Rect({
          left: 0,
          top: -23,
          width: text.width + 15,
          height: 20,
          rx: 2,
          fill: "#ffffffad",
          stroke: newColor,
          strokeWidth: 1,
          originX: "center",
          originY: "center"
        });

        const currentPositionLeft = object.getCenterPoint();
        object.set("left", currentPositionLeft.x - (rect.width) / 2);
        object.set("width", rect.width);
        object.add(rect);
        object.add(text);
      }
      // canvas.bringToFront(object)
      canvas.renderAll();
    } else {
      console.error("Object with the specified ID not found on the canvas.");
    }
  };

  const fromPinRender = async (obj, type, name) => {
    console.log(obj,obj.name, type, name,"obj, type, name");
    if (obj.name === "location") {
      addLocationPin(
        obj,
        type === "from" ? projectSettings?.start_color : obj?.color,
        canvas
      );
    } else if (obj.name === "product") {
      addProductPin(
        obj,
        type === "from" ? projectSettings?.start_color : obj?.color,
        canvas
      );
    } else if (obj.name === "beacon") {
      addBeaconPin(
        obj,
        type === "from" ? projectSettings?.start_color : obj?.color,
        canvas
      );
    } else if (obj.name === "amenity") {
      addAmenityPin(
        obj,
        type === "from" ? projectSettings?.start_color : obj?.icon_color,
        canvas
      );
    } else if (obj.name === "safety") {
      addSafetyPin(
        obj,
        type === "from" ? projectSettings?.start_color : obj?.icon_color,
        canvas
      );
    } else if (obj.name === "vertical") {
      let verticalName;
      if (type !== "from") {
        verticalName = findNameOfVertical(obj);
      }
      if (type !== "from" && verticalName) {
        changeObjectColorById(
          obj,
          type === "from" ? projectSettings?.start_color : obj?.icon_color,
          verticalName
        );
      } else {
        addVerticalPin(
          obj,
          type === "from" ? projectSettings?.start_color : obj?.icon_color,
          canvas,
          verticalName
        );
      }
    }
  };

  const findNameOfVertical = (obj) => {
    const vertical = allVerticalTransports?.find(
      (item) => item?.vtd_id == obj?.draft_id
    );
    // console.log(vertical, 'vertical')
    const item = {
      ...vertical,
      to_vt_id: vertical?.vt_id
    };
    const isAccending =
      floorIndex(selTraversibleDetails?.from_floor_id) < floorIndex(selTraversibleDetails?.to_floor_id);
    const result = findHighestFloorPlanId(
      allVerticalTransports,
      item,
      isAccending
    );
    return result ? `${result?.name} to ${result?.floor_plan}` : null;
  };

  const attainFromToDestinations = () => {
    const toData = localStorage.getItem("to_location");
    const fromData = localStorage.getItem("from_location");
    // console.log(toData,fromData,"sdjbfgsjebfjsdbvfjhsdfjhsdbfv")
    if (fromData) {
      const data = JSON.parse(fromData);
      setAllVerticalTransports(projectSettings?.vt_details);
      setAllPointsAndEdges(projectSettings?.fp_details);
      getFloorDetails(data.from_floor_plan_id, fromData, toData);  

      // let scaleRatio = Math.min(
      //   window.innerWidth / canvas.getWidth(),
      //   600 / canvas.getHeight()
      // );
      // JSON.parse(toData)


      
      // setTimeout(() => {
      //   let fromObject = findObjectByEnc_id(JSON.parse(fromData)?.from_id,canvas)
      //   let toObject = findObjectByEnc_id(JSON.parse(toData)?.to_id,canvas)
      //   const scaleRatio = fitObjectsInView(canvas, fromObject,toObject)
      //   if(scaleRatio){
      //     const viewportTransform = canvas.viewportTransform;
      //     applyZoom(canvas, scaleRatio?.centerX, scaleRatio?.centerY, scaleRatio?.newZoom, "mouse");
      //     if (viewportTransform) {
      //       viewportTransform[4] = canvas.width / 2 - scaleRatio?.centerX * scaleRatio?.newZoom;
      //       viewportTransform[5] = canvas.height / 2 - scaleRatio?.centerY * scaleRatio?.newZoom;
      //       canvas.requestRenderAll();
      //     }
      //     canvas.setZoom(scaleRatio?.newZoom);
      //     handleCanvasContentSize();
      //   }
      // }, 2000);
      

    }

    
  };

  const showPath = (fromdata, toDataItem, reverseCondition) => {
    let shortestPath = null;
    let from =  `${fromdata.from_type_name}_${fromdata.from_draft_id}`;
    let to =  `${toDataItem.to_type_name}_${toDataItem.to_draft_id}`;
    // if (reverseCondition === true) {
    //     from = `${toDataItem.to_type_name}_${toDataItem.to_draft_id}`;
    //     to = `${fromdata.from_type_name}_${fromdata.from_draft_id}`;
    // }
    shortestPath = dijkstra(
      graph,
      from,
      to
    );
    setShortestPathPoints(shortestPath)
    let nodes = graph.getPositions();
    // console.log(shortestPath, "shortestPath");
    if (shortestPath) {
      // if (reverseCondition) {
      //     shortestPath.reverse();
      // }
        let pathArrayList = shortestPath.map(key => nodes[key]).filter(Boolean)
        // const scaleRatio = fitObjectsInView(canvas, nodes[from],nodes[to],pathArrayList)
        const scaleRatio = fitObjectsInView(canvas, pathArrayList)
        if(scaleRatio){
          canvas.setZoom(scaleRatio?.newZoom);
          const viewportTransform = canvas.viewportTransform;
          if (viewportTransform) {
            viewportTransform[4] = canvas.width / 2 - scaleRatio?.centerX * scaleRatio?.newZoom;
            viewportTransform[5] = canvas.height / 2 - scaleRatio?.centerY * scaleRatio?.newZoom;
            canvas.requestRenderAll();
          }
          handleCanvasContentSize();
        }else{
          dragToPoint(nodes[shortestPath[0]]);
        }
      setTimeout(() => {
        applyZoom(canvas, scaleRatio?.centerX, scaleRatio?.centerY, scaleRatio?.newZoom, "mouse");
        updateVisibleTiles(canvas,tilesRef)
      }, 1000);
      
// console.log("shortestPath")

      // dragToPoint(nodes[shortestPath[0]]);

      // original path line 
      shortestPath.forEach((p, id) => {
        if (id < shortestPath.length - 1) {
          let points = [
            nodes[p].x,
            nodes[p].y,
            nodes[shortestPath[id + 1]].x,
            nodes[shortestPath[id + 1]].y
          ];
          let line = new fabric.Line(points, {
            originalStrokeWidth: projectSettings?.navigation_thick ?? 3,
            strokeWidth: projectSettings?.navigation_thick ?? 3,
            stroke: projectSettings?.navigation_color ?? "red",
            selectable: false,
            name: "short_path",
            id: "short_path",
            // strokeDashArray: [5, 5],
            originX: "center",
            originY: "center",
            strokeUniform: true
          });
          canvas.add(line);
          // canvas.bringToFront(line)
        }
      });

// need to delete this 
      // const arrows = [];
      // const duration = 2000; // 2 seconds for all arrows
      
      // Create lines and arrows
      // shortestPath.forEach((p, id) => {
      //   if (id < shortestPath.length - 1) {
      //     let startX = nodes[p].x;
      //     let startY = nodes[p].y;
      //     let endX = nodes[shortestPath[id + 1]].x;
      //     let endY = nodes[shortestPath[id + 1]].y;
      
      //     let line = new fabric.Line([startX, startY, endX, endY], {
      //       originalStrokeWidth: projectSettings?.navigation_thick ?? 3,
      //       strokeWidth: projectSettings?.navigation_thick ?? 3,
      //       stroke: projectSettings?.navigation_color ?? "red",
      //       selectable: false,
      //       name: "short_path",
      //       id: "short_path",
      //       // strokeDashArray: [5, 5],
      //       originX: "center",
      //       originY: "center",
      //       strokeUniform: true
      //     });
      
      //     let angle = (Math.atan2(endY - startY, endX - startX) * 180 ) / Math.PI + 90;

      //     let arrow = new fabric.Triangle({
      //       fill: projectSettings?.navigation_color ?? "red",
      //       // left: points[0]?.x,
      //       // top: points[0]?.y,
      //       height: 15 + projectSettings?.navigation_thick,
      //       width: 15 + projectSettings?.navigation_thick,
      //       originX: "center",
      //       originY: "center",
      //       selectable: false,
      //       name: "arrow-head",
      //       ignoreZoom: true,
      //       skipAbsolute: true,
      //       scaleX: 1 / canvas?.getZoom(),
      //       scaleY: 1 / canvas?.getZoom(),
      //     });
      
      //     canvas.add(line);
      //     canvas.add(arrow);
      //     arrows.push({ arrow, startX, startY, endX, endY, angle });
      //   }
      // });
      
      // Animate all arrows
      // function animateArrows(startTime) {
      //   const currentTime = performance.now();
      //   let progress = (currentTime - startTime) / duration; 
      
      //   if (progress >= 1) {
      //     startTime = performance.now(); 
      //     progress = 0;
      //   }
      
      //   arrows.forEach(({ arrow, startX, startY, endX, endY,angle }) => {
      //     let newX = startX + (endX - startX) * progress;
      //     let newY = startY + (endY - startY) * progress;
      
      //     arrow.set({ left: newX, top: newY,angle: angle  });
      //   });
      
      //   canvas.renderAll();
      //   requestAnimationFrame(() => animateArrows(startTime));
      // }
      
      // Start animation
      // requestAnimationFrame(() => animateArrows(performance.now()));
      //  --------------------------------------


      // Draw arrows only at starting and ending points
      
      // console.log(shortestPath,"shortestPath");
      if (shortestPath.length >= 2) {
        let startNode = shortestPath[0];
        let endNode = shortestPath[shortestPath.length - 1];

        let startArrow = drawArrow(
          nodes[startNode],
          nodes[shortestPath[1]],
          "start"
        );
        
        let endArrow = drawArrow(
          nodes[endNode],
          nodes[shortestPath[shortestPath.length - 2]],
          "end"
        );

        // canvas.add(startArrow);
        // canvas.add(endArrow);
        // console.log(fromdata, toDataItem,"fromdata, toDataItem");
        pinNameTextBringtoFront()
        bringObjectsToFrontByName("short_path");
        bringFabricObjectsToFrontByDraftId(fromdata?.from_draft_id);
        const to_draft_id = checkToItem(toDataItem);
        bringFabricObjectsToFrontByDraftId(
          to_draft_id ?? toDataItem?.to_draft_id
        );
        // console.log(fromdata, toDataItem,"fromdata, toDataItem");
        highlightStartAndEndPoints(fromdata, toDataItem);

      }
      
    }
  };


  const drawArrow = (startPoint, endPoint, from) => {
    let points = [startPoint.x, startPoint.y, endPoint.x, endPoint.y];

    let arrowAngle =
      from === "start"
        ? Math.atan2(points[3] - points[1], points[2] - points[0])
        : Math.atan2(points[1] - points[3], points[0] - points[2]);

    const arrowTop =
      from === "start"
        ? getPointOnLine(...points, 17).start.y
        : getPointOnLine(...points, 17).start.y;
    const arrowLeft =
      from === "start"
        ? getPointOnLine(...points, 17).start.x
        : getPointOnLine(...points, 17).start.x;

    let arrow = new fabric.Triangle({
      angle: arrowAngle * (180 / Math.PI) + 90,
      fill: projectSettings?.navigation_color ?? "red",
      top: arrowTop,
      left: arrowLeft,
      height: 15 + projectSettings?.navigation_thick,
      width: 15 + projectSettings?.navigation_thick,
      originX: "center",
      originY: "center",
      selectable: false,
      name: "arrow",
      located: from,
      id: from
    });

    return arrow;
  };

  useEffect(() => {
    if (canvas) {
      attainFromToDestinations();
    }
  }, [canvas]);

  useLayoutEffect(() => {
    if (!canvas) {
      initialiseFabric();
    }
  }, [canvas]);


  function findHighestFloorPlanId(array, item, isAscending) {
    let maxFloorPlanId = isAscending ? -Infinity : Infinity;
    let connectedItem;

    if (isAscending) {
      array.forEach((arrItem) => {
        if (arrItem.vt_id === item.to_vt_id) {
          if (
            floorIndex(arrItem.floor_plan_id) > maxFloorPlanId &&
            floorIndex(arrItem.floor_plan_id) <= floorIndex(selTraversibleDetails?.to_floor_id)
          ) {
            maxFloorPlanId = floorIndex(arrItem.floor_plan_id);
            connectedItem = arrItem;
          }
        }
      });
    } else {
      array.forEach((arrItem) => {
        if (arrItem.vt_id === item.to_vt_id) {
          if (
            floorIndex(arrItem.floor_plan_id) < maxFloorPlanId &&
            floorIndex(arrItem.floor_plan_id) >= floorIndex(selTraversibleDetails?.to_floor_id)
          ) {
            maxFloorPlanId = floorIndex(arrItem.floor_plan_id);
            connectedItem = arrItem;
          }
        }
      });
    }
    return connectedItem;
  }

  function convertVerticalPinData(array) {
    // console.log(array,"array")
    const data = array?.map((item) => ({
      enc_id: item?.vtd_id,
      vtd_id: item?.vtd_id,
      vt_id: item?.vt_id,
      name: `vertical_${item?.vtd_id}`,
      movement_direction:item?.movement_direction,
      icon_id:item?.icon_id,
      type: 6,
      to_floor_id: item?.floor_plan_id,
      vertical_transport_id: item?.vt_id,
      vt_name: item?.vt_name,
      floor_plan: item?.floor_plan,
    }));
    return data ?? [];
  }


  function findShortestVTS(data, from) {
    let minLength = Infinity;
    let shortestItem = null;
    data?.forEach((item) => {
      const path = dijkstraWithLength(graph, from, item.name);
      // console.log(path, "sp");
      if (path?.length < minLength) {
        minLength = path.length;
        shortestItem = item;
      }
    });
    console.log(graph, "graph");
    return shortestItem;
  }

  const handlePontsAndEdges = (floor_id) => {
    const floorPlanDtls = allPointsAndEdges?.find(
      (item) => item?.fp_id == floor_id
    );
    if (floorPlanDtls?.edges_data) {
      const edges = JSON.parse(floorPlanDtls.edges_data);
      // // console.log(edges, "edges");
      if (edges?.length === 0) {
        graph.restoreEdges();
      } else {
        graph.restoreEdges(edges);
        const nodeFromAPI = Object.keys(edges);
        nodeFromAPI?.forEach((n) => {
          graph.addNode(n);
        });
      }
    } else {
      graph.restoreEdges();
    }
    if (floorPlanDtls?.points_data) {
      const points = JSON.parse(floorPlanDtls.points_data);
      if (points?.length === 0) {
        graph.restorePositions();
      } else {
        graph.restorePositions(points);
      }
    } else {
      graph.restorePositions();
    }
  };

  const handlePontsAndEdgesForGraphCopy = (floor_id,graph) => {
    const floorPlanDtls = allPointsAndEdges?.find(
      (item) => item?.fp_id == floor_id
    );
    // console.log(allPointsAndEdges,floorPlanDtls,"allPointsAndEdges,floorPlanDtls");
    // console.log(floorPlanDtls.floor_plan,"edge data floor ")
    if (floorPlanDtls?.edges_data) {
      const edges = JSON.parse(floorPlanDtls.edges_data);
      // // console.log(edges, "edges");
      if (edges?.length === 0) {
        graph.restoreEdges();
      } else {
        graph.restoreEdges(edges);
        const nodeFromAPI = Object.keys(edges);
        nodeFromAPI?.forEach((n) => {
          graph.addNode(n);
        });
      }
    } else {
      graph.restoreEdges();
    }
    if (floorPlanDtls?.points_data) {
      const points = JSON.parse(floorPlanDtls.points_data);
      if (points?.length === 0) {
        graph.restorePositions();
      } else {
        graph.restorePositions(points);
      }
    } else {
      graph.restorePositions();
    }
  };

  function checkFloorConnections(selectedVTS, isAccending) {
    const matchingItems = [];
    for (let item1 of selectedVTS) {
      for (let item2 of allVerticalTransports) {
        // if (item1.vt_id === item2.vt_id && item2.floor_plan_id === floor_id) {
        //   matchingItems.push(item1);
        // }
        if (
          isAccending
            ? item1.vt_id === item2.vt_id &&
            floorIndex(item1?.floor_plan_id) <= floorIndex(selTraversibleDetails?.to_floor_id)
            : item1.vt_id === item2.vt_id &&
            floorIndex(item1?.floor_plan_id) >= floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          matchingItems.push(item2);
        }
      }
    }
    if (matchingItems.length > 0) {
      return matchingItems;
    } else {
      return null;
    }
  }

  function checkTopFloorVTS(data, isAccending) {
    let checkDirectConnectionReturn = checkFloorConnections(data, isAccending);
    console.log(checkDirectConnectionReturn, "checkDirectConnectionReturn");
    const dataArray = checkDirectConnectionReturn ?? data;
    const filteredArray = dataArray.reduce((acc, current) => {
      const floorPlanId = parseInt(floorIndex(current.floor_plan_id));
      if (isAccending) {
        if (
          floorPlanId > (floorIndex(acc[0]?.floor_plan_id) || 0) &&
          floorPlanId <= floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          return [current];
        } else if (floorPlanId === (floorIndex(acc[0]?.floor_plan_id) || 0)) {
          acc.push(current);
        }
      } else {
        if (
          floorPlanId <
          (floorIndex(acc[0]?.floor_plan_id) ||
            floorIndex(dataArray[dataArray?.length - 1]?.floor_plan_id)) &&
          floorPlanId >= floorIndex(selTraversibleDetails?.to_floor_id)
        ) {
          return [current];
        } else if (
          floorPlanId ===
          (floorIndex(acc[0]?.floor_plan_id) ||
            floorIndex(dataArray[dataArray?.length - 1]?.floor_plan_id))
        ) {
          acc.push(current);
        }
      }
      return acc;
    }, []);
    console.log(filteredArray, "filteredArray");
    const topVTIDS = filteredArray?.map((item) => item?.vt_id);
    const topFloorID = filteredArray?.map((item) => floorIndex(item?.floor_plan_id));
    const filteredData = data.filter(
      (item) =>
        topVTIDS.includes(item?.vt_id) &&
        !topFloorID.includes(floorIndex(item?.floor_plan_id))
    );
    return filteredData;
  }

  const getCurrentIndex = (id) => {
    const item = floorsListrOrder.find((item) => item?.enc_id == id)
    // console.log(item?.display_index, 'current index')
    return item?.display_index
  }

  const floorIndex = (id) => {
    let floorss
    if (getCurrentIndex(selTraversibleDetails?.from_floor_id) > getCurrentIndex(selTraversibleDetails?.to_floor_id)) {
      floorss = floorsListrOrder.sort((a, b) => b.display_index - a.display_index);
      // console.log(floorss, 'sort')
    } else {
      floorss = floorsListrOrder.sort((a, b) => a.display_index - b.display_index);
      // console.log(floorss, 'sort')
    }

    const index = floorss?.findIndex(item => id == item.enc_id)
    return index;
  }

  // copy code from web

  function findMatchingItems(arr1, arr2, type) {
    if(arr1[0] == null || arr2[0] === null ) return []
    let newarray = allVerticalTransports.filter((item) => {
      if (item.floor_plan_id === selTraversibleDetails?.to_floor_id && type === item.icon_id) {
        return item
      }
    })

    let matchingItems = [];

    const isItemInAllItems = newarray.find(allItem => 
      arr1.some( item => item.vertical_transport_id === allItem.vt_id)
    );

    // console.log(arr1, arr2, newarray, "jdfjdkshfjsdk")
    
    if (!isItemInAllItems) {
      return matchingItems
    }
    // Loop through each item in arr2
    for (let item2 of arr2) {
      // Check if any item in arr1 has the same vt_id as the current item in arr2
      // let matchingItem1 = arr1.find(item1 => {
      //   return item1.vt_id === item2.vt_id
      //   // let itemToReturn = newarray.find(allItem => 
      //   //   allItem.vt_id === item1.vertical_transport_id
      //   // );
      //   // return itemToReturn.vtd_id === item2.enc_id
      // });
      let matchingItem1 = arr1.find(item1 => {
        return item1.vertical_transport_id === item2.vertical_transport_id
        // let itemToReturn = newarray.find(allItem => 
        //   allItem.vt_id === item1.vertical_transport_id
        // );
        // return itemToReturn.vtd_id === item2.enc_id
      });

      // let matchingItem1 = isItemInAllItems.vtd_id === item2.enc_id ? arr1.find(item1 => isItemInAllItems.vt_id === item1.vertical_transport_id) : false
      // console.log(matchingItem1,item2,"matchingItem1")
      // If a match is found, add both items (from arr1 and arr2) to the matchingItems array
      if (matchingItem1) {
        handlePontsAndEdges(item2?.to_floor_id)
        // console.log(matchingItem1?.to_floor_id,item2,graph,item2.name, selTraversibleDetails.to,"path")
        const path = dijkstraWithLength(graph, item2.name, selTraversibleDetails.to);
        if (path) {
          matchingItems.push({
              from: matchingItem1,
              to: item2
          });
          
        }
      }
      
    }
    
    return matchingItems;
  } 

  const findNearestFloor = (isIndirectlyConnected,fromvt,tovt) => {
    return isIndirectlyConnected.reduce((acc, current) => {
      if (findFloorIndexToCheck(fromvt[0].to_floor_id) === findFloorIndexToCheck(acc?.floor_plan_id)) {
        acc = current
      }else if (findFloorIndexToCheck( acc?.floor_plan_id) > findFloorIndexToCheck(current?.floor_plan_id) && findFloorIndexToCheck(acc?.floor_plan_id) !== findFloorIndexToCheck(fromvt[0].to_floor_id)) {
      }
      return acc
   }, isIndirectlyConnected[0])
    
  }

  const findFloorIndexToCheck = (id) => {
    let list = [...floorPlans];
    let allfloorlist = list.sort((a, b) => a.display_index - b.display_index);
    return allfloorlist.findIndex(fp => (fp.enc_id === id))
  }

  function getMatchingFloorPlan(fromPoints, toPoints, verticalTransports) {
    if(fromPoints[0] == null || toPoints[0] === null ) return []
    // console.log(fromPoints, toPoints, verticalTransports,fromPoints.length, toPoints.length == 0, "fromPoints, toPoints, verticalTransports")
    // if(fromPoints.length  = 0 || toPoints.length == 0) return []
    let matchingFloorPlans = []; // Array to store all matching floor plans

    // Loop through all floors in verticalTransports
    for (let floor of verticalTransports) {
      let transports = floor.transports;

      // Find matching transports from 'fromPoints' in the current floor
      let matchingFromTransports = transports.filter(transport =>
          // fromPoints.some(fromPoint => fromPoint.vt_id === transport.vt_id)
          fromPoints.some(fromPoint => fromPoint.vertical_transport_id === transport.vt_id)
      );

      // Find matching transports from 'toPoints' in the current floor
      let matchingToTransports = transports.filter(transport =>
          // toPoints.some(toPoint => toPoint.vt_id === transport.vt_id)
          toPoints.some(toPoint => toPoint.vertical_transport_id === transport.vt_id)
      );
      // If both fromPoints and toPoints have matching transports on this floor
      if (matchingFromTransports.length > 0 && matchingToTransports.length > 0) {
        // console.log(matchingFromTransports,matchingToTransports,"matchingFromTransports,matchingToTransports")
        matchingFloorPlans.push({
              floor_plan_id: floor.floor_plan, // Floor plan id
              floor_plan: floor.floor_plan_name, // Floor plan name
              fromTransports: convertVerticalPinData(matchingFromTransports), // Matching transports from 'fromPoints'
              toTransports: convertVerticalPinData(matchingToTransports) // Matching transports from 'toPoints'
          });
      }
    }

    return matchingFloorPlans; 
  }

  const checkIfItemIsPresent = (floor) => {
    handlePontsAndEdges(floor?.floor_plan_id)
    const path = dijkstraWithLength(graph, floor.fromTransports[0].name, floor.toTransports[0].name);
    return path
  }

  const matchConnectionByAnyTransport = (allVerticalTransports, fromvt, tovt, iconid = false) => {

    const transportsByFloor = floorPlans.map(floor => {
      return {
          floor_plan: floor.enc_id,
          floor_plan_name: floor.floor_plan,
        transports: allVerticalTransports.filter(transport => {
          if (iconid) {
           return  transport.floor_plan_id === floor.enc_id && transport.icon_id === iconid
          } else {
            return transport.floor_plan_id === floor.enc_id
          }
        })
      };
    });
// console.log(transportsByFloor,"all vertical transport")
    let fromFloor = transportsByFloor.filter((item) => item.floor_plan === selTraversibleDetails.from_floor_id)
    let toFloor = transportsByFloor.filter((item) => item.floor_plan === selTraversibleDetails.to_floor_id)
  
    function findTransportItems(currentTransport, floorplanId, iconid = false) {
    // console.log(iconid,"icon id in find transport item")
      const countOccurrences = (floorPlan) => {
        return floorplanId.filter((id) => id === floorPlan).length;
      };
      // console.log(transportsByFloor,floorplanId,"transportsByFloor")
      return transportsByFloor.filter(
        (item) =>
            item.transports.some(
              (transport) => transport.vt_id === currentTransport.vt_id && transport.floor_plan_id != currentTransport.floor_plan_id 
            )&& !floorplanId.includes(item.floor_plan)
          // )&& countOccurrences(item.floor_plan) <= 1
      );
    }
  
    function checkTransportOnDestination(currentTransport, toFloor,transport) {
      // return currentTransport.some((item) =>
      //   toFloor.some((to) => to.floor_plan === item.floor_plan)
      // );
      return toFloor.some((to) => to.floor_plan === transport.floor_plan_id)
    }
  
    // Recursive function to traverse through floors and transports
    function traverseTransports(transport, floorplanId, connectedPath, toFloor,previousTransportId = null,iconid=false) {
      let transportItemsByFloor = findTransportItems(transport, floorplanId,iconid);
  // console.log(transportItemsByFloor,"transportItemsByFloor")
      transportItemsByFloor.forEach(floorPlan => {
        // Find the index of the transport item with vt_id 215
        const transportIndex = floorPlan.transports.findIndex(item => item.vt_id === transport.vt_id);
        
        // If the item is found, move it to the beginning of the transports array
        if (transportIndex > -1) {
            const [transportItem] = floorPlan.transports.splice(transportIndex, 1); // Remove the item
            floorPlan.transports.unshift(transportItem); // Add it at the beginning
        }
      });
  // console.log(transportItemsByFloor,connectedPath,"connectedPath")
      if (checkTransportOnDestination(transportItemsByFloor, toFloor,transport)) {
        return connectedPath;
      }

      function checkIfItIsReadyToPushOrNot(transportItem, arrayitems, toFloor) {
        
        // console.log(transportItem, arrayitems, toFloor,"transportItem, transports, toFloor")
        let isGreaterthan2 = arrayitems.length > 1 
        const isTransportPresent = toFloor.some(floorPlan1 => 
          floorPlan1.transports.some(transport1 => 
            arrayitems.some(transport2 => transport1.vt_id === transport2.vt_id)
          )
        );
        const isfromFloorPresent = fromFloor.some(floorPlan1 => 
          floorPlan1.transports.some(transport1 => 
            arrayitems.some(transport2 => transport1.vt_id === transport2.vt_id)
          )
        );
        // console.log(isGreaterthan2, isTransportPresent, isfromFloorPresent, "isGreaterthan2,isTransportPresent")
        if (!isGreaterthan2 && !isTransportPresent) {
          return false
        } else {
          return true
        }
      }
    
      for (let transportFloor of transportItemsByFloor) {
        floorplanId.push(transportFloor.floor_plan);
        for (let transportItem of transportFloor.transports) {
          if (checkIfItIsReadyToPushOrNot(transportItem, transportFloor.transports, toFloor)) {
            // console.log(connectedPath,"connectedPath reduy to push")
            connectedPath.push({
              transportItem:transportItem,
              transportID: transportItem.vt_id,
              transportName: transportItem.vt_name,
              floor: transportFloor.floor_plan,
              floor_plan_name: transportFloor.floor_plan_name,
            });
          }
    
          let result = traverseTransports(
            transportItem,
            floorplanId,
            connectedPath,
            toFloor,
            transport,
            iconid
          );
          if (result) {
            return result;
          }
        }
      }
    
      return null; // No connection found, return null
    }
  
    let floorplanId = []; 
    let connection = fromFloor.map((floor) => {
      floorplanId.push(floor.floor_plan);
      let connectedPath = [];
    
      for (let transport of floor.transports) {
        // console.log(transport,"transport")
        connectedPath.push({
          transportItem:transport,
          transportID: transport.vt_id,
          transportName: transport.vt_name,
          floor: floor.floor_plan,
          floor_plan_name: floor.floor_plan_name,
        });
    
        let result = traverseTransports(transport, floorplanId, connectedPath, toFloor, null, iconid);
        
        if (result) {
          return result;
        }
      }
    
      return null; // No connection found on this floor
    });
  
    return connection[0]
  }


  /**  vertical transport code  */
  const onSelectVerticalTransport = (data) => {
    let floorgraphArray = []
    floorPlans.forEach((item) => {
      const floorGraph = new Graph();
      handlePontsAndEdgesForGraphCopy(item?.enc_id, floorGraph);

      // console.log(item);

      floorgraphArray.push({
        id: item?.enc_id,
        graphData: floorGraph 
      })
  
    });

    
    const mergedGraph = combineFloors(floorgraphArray, convertVerticalPinData(allVerticalTransports), 10, data?.icon_id)
    
    let fromlocation = `f${selTraversibleDetails.from_floor_id}_${selTraversibleDetails.from}`
    let tolocation = `f${selTraversibleDetails.to_floor_id}_${selTraversibleDetails.to}`
    
    
    let result1 = dijkstraWithLength(mergedGraph, fromlocation, tolocation);
    let result2;

    if (!result1) {
      let combined = combineFloors(floorgraphArray, convertVerticalPinData(allVerticalTransports),10);
      result2 = dijkstraWithLength(
        combined,
        fromlocation, tolocation
      );
    }

    let result;
    removeFabricObjectsByName(canvas, "short_path");
    removeArrows() 
    if (result1) {
      result = result1;
      processPathConnection(result)
    } else if(result2){
      // toast.warning(
      //   "Sorry! The chosen vertical transport has no connection to your destination. We will find another best route for the destination"
      // );
      result = result2;
      setVerticalPathConnectionResult(result2)
      TransportModaltoggle()
      // toggle()
    } 


    if (!result) {
      toast.error(
        "Sorry! We couldn't establish a connection to your destination."
      );      
    }

    toggle();
    
  };
  
  function combineFloors(floors, verticalTransports, transitionWeight = 10, selectedIconId = null) {
    const combined = { nodes: [], edges: {}, positions: {} };

    // Add floor nodes/edges with prefixes (same as before)
    floors.forEach(floor => {
      const floorId = floor.id;
      const prefix = `f${floorId}_`;

      // Process nodes and edges
      const nodes = new Set();
      for (const node in floor.graphData.edges) {
          nodes.add(node);
          for (const neighbor in floor.graphData.edges[node]) {
              nodes.add(neighbor);
          }
      }

      // Add nodes to combined graph
      nodes.forEach(node => {
          const prefixedNode = prefix + node;
          combined.nodes.push(prefixedNode);
          combined.positions[prefixedNode] = { ...floor.graphData.positions[node] };
      });

      // Add edges to combined graph
      for (const node in floor.graphData.edges) {
          const prefixedNode = prefix + node;
          if (!combined.edges[prefixedNode]) {
              combined.edges[prefixedNode] = {};
          }
          for (const neighbor in floor.graphData.edges[node]) {
              const prefixedNeighbor = prefix + neighbor;
              combined.edges[prefixedNode][prefixedNeighbor] = floor.graphData.edges[node][neighbor];
          }
      }
    });

    // Filter and group vertical transports
    const vtGroups = {};
    const relevantVTs = verticalTransports.filter(vt => 
      selectedIconId === null || vt.icon_id === selectedIconId
    );

    // console.log(verticalTransports,relevantVTs,selectedIconId,"verticalTransports,relevantVTs");
  
    relevantVTs.forEach(vt => {
        if (!vtGroups[vt.vt_id]) vtGroups[vt.vt_id] = [];
        vtGroups[vt.vt_id].push(vt);
    });

    // Create floor order map based on array position
    const floorOrder = new Map();
    floors.forEach((floor, index) => floorOrder.set(floor.id, index));

    // Connect vertical transports with directional edges
    Object.values(vtGroups).forEach(vtGroup => {
        const vtDetails = vtGroup
            .map(vt => {
                const floor = floors.find(f => f.graphData.edges.hasOwnProperty(vt.name));
                return floor ? {
                    node: `f${floor.id}_${vt.name}`,
                    floorId: floor.id,
                    direction: vt.movement_direction
                } : null;
            })
            .filter(Boolean);

        // Create all possible pairs
        for (let i = 0; i < vtDetails.length; i++) {
            for (let j = i+1; j < vtDetails.length; j++) {
                const a = vtDetails[i];
                const b = vtDetails[j];
                
                // Get floor positions
                const aPos = floorOrder.get(a.floorId);
                const bPos = floorOrder.get(b.floorId);
                
                // A to B connection
                if (aPos < bPos) { // A is higher floor
                    if (a.direction === 'down' || a.direction === 'bidirectional') {
                        if (!combined.edges[a.node]) combined.edges[a.node] = {};
                        combined.edges[a.node][b.node] = transitionWeight;
                    }
                } else { // A is lower floor
                    if (a.direction === 'up' || a.direction === 'bidirectional') {
                        if (!combined.edges[a.node]) combined.edges[a.node] = {};
                        combined.edges[a.node][b.node] = transitionWeight;
                    }
                }

                // B to A connection
                if (bPos < aPos) { // B is higher floor
                    if (b.direction === 'down' || b.direction === 'bidirectional') {
                        if (!combined.edges[b.node]) combined.edges[b.node] = {};
                        combined.edges[b.node][a.node] = transitionWeight;
                    }
                } else { // B is lower floor
                    if (b.direction === 'up' || b.direction === 'bidirectional') {
                        if (!combined.edges[b.node]) combined.edges[b.node] = {};
                        combined.edges[b.node][a.node] = transitionWeight;
                    }
                }
            }
        }
    });

    // console.log(relevantVTs,floors,combined, "relevantVTs");
    return combined;
  }

  const processPathConnection = (result) =>{
    let pathArray=[]
      if (result?.floorwiseList) {
        result.floorwiseList.forEach((item,index) => {
          const key = Object.keys(item)[0]; 
          const value = item[key]; 

          let firstitem = value[0]
          let lastitem = value[value.length - 1]
          let floorTransport;
          if (value[0].split("_")[0] == "vertical") {
            floorTransport = allVerticalTransports.find((item) => item.vtd_id == value[0].split("_")[1])
          }
          if (value[value.length - 1].split("_")[0] == "vertical") {
            floorTransport = allVerticalTransports.find((item) => item.vtd_id == value[value.length - 1].split("_")[1])
          }
          
          let pathitem = {
            floor_plan_id: key,
            from: firstitem,
            to: lastitem,
            to_vt_id: floorTransport.vtd_id,
            floor_plan: key,
            vt_name: floorTransport.vt_name,
            fromItem:{
              from_type_name: value[0].split("_")[0],
              from_draft_id: value[0].split("_")[1],
              from_id: value[0].split("_")[1],
              type: 6,
              from: firstitem
            },
            toItem:{
              to_type_name: value[value.length - 1].split("_")[0],
              to_draft_id: value[value.length - 1].split("_")[1],
              to_id: value[value.length - 1].split("_")[1],
              type: 6,
              to: lastitem,
              enc_id: value[value.length - 1].split("_")[1]
            }
          }
          pathArray.push(pathitem)
        });
      }

      console.log(pathArray,"pathArray");

      setSelTraversibleDetails((prev) => ({
        ...prev,
        pathArray,
        is_miltiple: true,
      }));
      
      if (pathArray?.length > 0) {
        handlePontsAndEdges(selTraversibleDetails?.from_floor_id);
        
        if (
          pathArray[pathArray?.length - 1]?.floor_plan_id ==
          selTraversibleDetails?.to_floor_id
        ) {
          showPath(pathArray[0].fromItem,pathArray[0].toItem);
          localStorage.setItem("pathLength", pathArray.length)
          localStorage.setItem("currentLength", 1)
        } else {
          removeArrows() 
          toast.error(
            "Sorry! The chosen vertical transport has no connection to your destination."
          );
        }
      }
  }
  
  const [pathModal, setPathModal] = useState(false)
  const TransportModaltoggle = () => setPathModal(!pathModal);

  const FindSecondaryPathCalculation = () => {
    processPathConnection(verticalPathConnectionResult)
  }

  /**  vertical transport code over */


  const convertPathArrayValue = (data) => {
    const splitFromData = splitWithUnderScore(data?.from);
    const splitToData = splitWithUnderScore(data?.to);
    // console.log(data,"convertPathArrayValue");
    const from = {
      from_type_name: splitFromData?.name,
      from_draft_id: splitFromData?.id,
      from_id: data?.floor_plan_id,
      type: data?.fromItem?.type,
      from: data?.fromItem?.from,
      name: data?.fromItem?.from_type_name
    };
    const to = {
      to_type_name: splitToData?.name,
      to_draft_id: splitToData?.id,
      to_id: data?.toItem?.to_id,
      type: data?.toItem?.type,
      to: data?.toItem?.to,
      enc_id: data?.toItem?.to_id,
      name: data?.toItem?.to_type_name
    };

    // const from = {
    //   from_type_name: splitFromData?.name,
    //   from_draft_id: splitFromData?.id,
    //   from_id: data?.from_id,
    //   type: data?.from_type,
    //   from: data?.from,
    //   name: data?.name
    // };
    // const to = {
    //   to_type_name: splitToData?.name,
    //   to_draft_id: splitToData?.id,
    //   to_id: data?.to_id,
    //   type: data?.to_type,
    //   to: data?.to,
    //   enc_id: data?.to_id,
    //   name: data?.name
    // };


    return { from, to };
  };

  const splitWithUnderScore = (value) => {
    const data = value.split("_");
    const item = {
      name: data[0],
      id: data[1]
    };
    return item;
  };

  const handleNextPreviousClick = async (i, type) => {
    let index;
    if (type == "next") {
      index = i + 1;
    } else {
      index = i - 1;
    }
    // console.log(selTraversibleDetails, 'selTraversibleDetails')
    const nextFloorData = selTraversibleDetails?.pathArray[index];
    const pathValue = convertPathArrayValue(nextFloorData);

    const returnValue = await switchFloorData(
      nextFloorData?.floor_plan_id,
      pathValue?.from,
      pathValue?.to
    );
    const LastFloorReverse = index === selTraversibleDetails?.pathArray?.length - 1
    console.log(LastFloorReverse, 'LastFloorReverse')
    if (returnValue) {
      setTimeout(() => {
        showPath(pathValue?.from, pathValue?.to, LastFloorReverse);
      }, 500);
    }

    setSelTraversibleDetails((prev) => ({
      ...prev,
      isNext: index
    }));
  };

  const switchFloorData = async (floorId, from, to) => {
    
    setCurrentFloor(floorId);
    istaskrendered.current = false
    try {
      const response = await getRequest(`floor-data/${floorId}`);
      const data = response.data.floor_data ?? {};
      setSelFloorData(data);
      const tracings = data?.tracings ? JSON.parse(data?.tracings ?? []) : [];
      const tracingCircle = data?.circle_data ? JSON.parse(data?.circle_data ?? []) : [];
      const texts = data?.text ? JSON.parse(data?.text ?? []) : [];

      const locations = data?.location_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const products = data?.product_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const beacons = data?.beacon_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const amenities = data?.amenity_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const safeties = data?.safeties_data.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));
      const vercalTransports = data?.vertical_transports.map((item) => ({
        ...item,
        position: JSON.parse(item?.positions)
      }));


      removeArrows() 
      // console.log("arrow removed");

      setAllVerticalData(vercalTransports);
      const filterVT = removeDuplicates(vercalTransports);
      setVerticalData(filterVT);
      reinitialiseFabric(canvas);
      renderTracingCircle(canvas, projectSettings, (tracingCircle ?? []));
      renderTracings(canvas, projectSettings, (tracings ?? []));

      renderTexts(canvas, (texts ?? []));
      renderLocations(locations ?? []);
      if (from?.type == 2 || to?.type == 2) {
        renderProducts(products ?? [], from, to);
      }
      renderBeacons((beacons ?? []), projectSettings, canvas);
      renderAmenities((amenities ?? []), canvas, projectSettings);
      renderSafeties((safeties ?? []), canvas, projectSettings);
      renderVerticalTransport((vercalTransports ?? []), canvas, projectSettings);

      handleTraversibleData(data);
      handleCanvasContentSize();
      const floorImage = data?.cropped_path ? image_url + data?.cropped_path : null
      // canvasBackGroundImage(canvas, floorImage, data)
      console.log(floorImage,"floorImage jnfnjfnerjfejnk");
      handleImageLoad(floorImage, data)
      setProjectData(data)
      setTimeout(() => {
        istaskrendered.current = true
      }, 1000); 
      
      return data;
    } catch (error) { }
  };

  const [tiles, setTiles] = useState([]);
  const tilesRef = useRef([]);
  const [projectData, setProjectData] = useState({});
  
  useEffect(()=>{
    if(tiles.length > 0){
      updateVisibleTiles(canvas,tilesRef)
    }
  },[tiles])

  const handleImageLoad =  (imageUrl,data) => {
    if(data?.cropped_path && data?.show_image == 1){
      if(imageUrl === null){
        tilesRef.current = "no-image"
      }else if(imageUrl.endsWith('.svg')){
          tilesRef.current = "no-image"
          canvasBackGroundImage(canvas, imageUrl, data, istaskrendered, JSON.parse(data?.img_size))
      }else{
        setCanvasBackgroundImageAsimage(imageUrl,data,tilesRef,canvas,setTiles)
        istaskrendered.current = true
      }
    }else{
      istaskrendered.current = true
      tilesRef.current = "no-image"
    }
  };


  const handleWheelchairCheck = (e) => {
    const isWheelchair = e.target.checked;
    if (isWheelchair) {
      // console.log(verticalTransports, 'verticalTransport')
      const verticalTransport = verticalData?.map((item) => ({
        ...item,
        noAccess: item?.is_wheelchair == 1 ? false : true
      }));

      setVerticalData(verticalTransport);
      console.log(verticalTransport, 'verticalTransport')
      const sortWithWheelChair = allVerticalTransports?.filter(
        (item) => item?.is_wheelchair == 1
      );
      console.log(sortWithWheelChair, 'sortWithWheelChair')
      setAllVerticalTransports(sortWithWheelChair);
    } else {
      const verticalTransport = verticalData?.map((item) => ({
        ...item,
        noAccess: false
      }));
      setVerticalData(verticalTransport);
      setAllVerticalTransports(projectSettings?.vt_details);
    }
  };

  const onEndDirection = (type) => {
    // localStorage.removeItem("to_location");
    if (type == "2") {
      navigate("/");
      localStorage.setItem("floorId", currentFloor);
    } else {
      removeFabricObjectsByName(canvas, "tracing");
      removeFabricObjectsByName(canvas, "boundary");
      removeFabricObjectsByName(canvas, "location");
      removeFabricObjectsByName(canvas, "product");
      removeFabricObjectsByName(canvas, "beacon");
      removeFabricObjectsByName(canvas, "amenity");
      removeFabricObjectsByName(canvas, "safety");
      removeFabricObjectsByName(canvas, "vertical");
      removeFabricObjectsByName(canvas, "short_path");
      removeFabricObjectsByName(canvas, "text");
      removeFabricObjectsByName(canvas, "arrow");

      floorData(destinationData?.to_floor_plan_id);
      setTimeout(() => {
        localStorage.removeItem("from_location");
        localStorage.setItem("from_location", JSON.stringify(fromSourseData));
      }, 1000);
    }
  };


  const floorData = async (floorId, type) => {
    try {
      const response = await getRequest(`floor-data/${floorId}`);
      const data = response.data.floor_data ?? {};
      const vercalTransport = data?.vertical_transports;
      const startFromVT = vercalTransport?.find(
        (item) => selectedVertical?.vt_id == item?.vt_id
      );
      if (!type) {
        let fromData = {
          from: startFromVT?.vt_name,
          from_draft_id: startFromVT?.vtd_id,
          from_type_name: "vertical",
          type: 6,
          from_floor_plan: data?.floor_plan,
          from_floor_plan_id: data?.enc_id,
          from_id: startFromVT?.enc_id
        };
        localStorage.setItem("from_location", JSON.stringify(fromData));
        attainFromToDestinations();
      } else if (type === "prev") {
        getFloorDetails(
          floorId,
          JSON.stringify(prevFloor?.from),
          JSON.stringify(prevFloor?.to[0]),
          type
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  const getAdvertisementsData = async (id) => {
    try {
      const reqUrl = `advertisement-data/${id}`;
      const response = await getRequest(reqUrl);
      const data = response.data?.adv_data ?? [];
      setAdDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const projectDetails = JSON.parse(project);
    getAdvertisementsData(projectDetails?.enc_id);
  }, [project]);


  const canvasRotationClick = () => {
    if(istaskrendered.current){
      let highlight_pin = null
      let centerPointPin = null
      canvas?.forEachObject((obj) => {
        if (obj?.types === "centerPointPin") {
          centerPointPin = obj
        } else if (obj?.types === "highlight_pin") {
          highlight_pin = obj
        }
      });

      let point
      if (centerPointPin) {
        point = {
          x: centerPointPin.left,
          y: centerPointPin.top
        }
        rotateCanvas(30, canvas, point)
        let radians = fabric.util.degreesToRadians(30);
        setGraphPositionPoints((prev) => {
          const updatedPoints = Object.fromEntries(
            Object.entries(prev).map(([key, val]) => {
              const pointOrigin = new fabric.Point(val.x, val.y);
              const newPointLoc = fabric.util.rotatePoint(pointOrigin, point, radians);
              return [key, { x: newPointLoc.x, y: newPointLoc.y }];
            })
          );
          return updatedPoints;
        });

      } else if (highlight_pin) {
        point = {
          x: highlight_pin.left,
          y: highlight_pin.top
        }
        rotateCanvas(30, canvas, point)
        let radians = fabric.util.degreesToRadians(30);
        setGraphPositionPoints((prev) => {
          const updatedPoints = Object.fromEntries(
            Object.entries(prev).map(([key, val]) => {
              const pointOrigin = new fabric.Point(val.x, val.y);
              const newPointLoc = fabric.util.rotatePoint(pointOrigin, point, radians);
              return [key, { x: newPointLoc.x, y: newPointLoc.y }];
            })
          );
          return updatedPoints;
        });
      }
    }
  }


  return (
    <>
      <Modal isOpen={pathModal} toggle={TransportModaltoggle}  style={{ zIndex: '999999 !important' }} backdrop={'static'} centered>      
        <ModalBody style={{ padding: '30px' }} className='confirm-to-go-back'>
          <p>
          The chosen vertical transport cannot reach the destination. However, we have found the shortest route. Would you like to proceed?
          </p>
          <div className='d-flex justify-content-center gap-3'> 
              
              <Button
                className="btn btn-primary cancel-button m-auto" 
                block
                onClick={() => {
                  setPathModal(false)
                  setModal(true)
                }}
              >
                No
              </Button>

              <Button
                className="btn btn-primary m-auto" 
                block
                onClick={() => {
                  setPathModal(false)
                  setModal(false)
                  FindSecondaryPathCalculation()
                }}
              >
                Yes
              </Button>
          </div> 
        </ModalBody >
      </Modal >


      {adDetails?.length > 0 && (
        <ImageSlider adDetails={adDetails} setCurrentFloor={setCurrentFloor} />
      )}
      <Main className="main-cont">
        {/* map */}
        <LogoContainer adDetails={adDetails} projectData={projectSettings} />
        <div className="canvas-div">
          <canvas id="canvas" />

        </div>

        
         {/* <div>
          <div className="canvas-orientation destination-rotate" style={{position:"initial"}}>
            <button onClick={canvasRotationClick}>
              <svg width={"32px"} height={"40px"} viewBox="0 0 100 100">
                <path d="M66.07,35.22a1.5,1.5,0,0,1-1.5-1.5v-12a1.5,1.5,0,0,1,1.5-1.5h12a1.5,1.5,0,0,1,0,3H67.57v10.5A1.5,1.5,0,0,1,66.07,35.22Z" />
                <path d="M33.93,79.78h-12a1.5,1.5,0,0,1,0-3h10.5V66.28a1.5,1.5,0,0,1,3,0v12A1.5,1.5,0,0,1,33.93,79.78Z" />
                <path d="M50,67.15a17.15,17.15,0,1,1,12.13-5A17.1,17.1,0,0,1,50,67.15Zm0-31.3A14.15,14.15,0,1,0,60,40,14.11,14.11,0,0,0,50,35.85Z" />
                <path d="M33.93,79.78a1.5,1.5,0,0,1-1.06-.44L18.73,65.2a21.5,21.5,0,0,1,0-30.41l8-8a1.5,1.5,0,0,1,2.12,2.12l-8,8a18.52,18.52,0,0,0,0,26.16L35,77.22a1.5,1.5,0,0,1-1.06,2.56Z" />
                <path d="M72.18,73.68a1.5,1.5,0,0,1-1.06-2.56l8-8a18.52,18.52,0,0,0,0-26.16L65,22.78a1.5,1.5,0,0,1,2.12-2.12L81.27,34.8a21.5,21.5,0,0,1,0,30.41l-8,8A1.5,1.5,0,0,1,72.18,73.68Z" />
              </svg>
            </button>
          </div>
        </div>  */}

        <FloorsList
          values={currentFloor}
          onchange={getFloorDetails}
          disabled={true}
          adDetails={adDetails}
          setFloorsListrOrder={setFloorsListrOrder}
          setFloorPlans={setFloorPlans}
        />
        <Sheet
          isOpen={open}
          onClose={() => setOpen(false)}
          disableDrag={true}
          style={{ zIndex: "1000" }}
          className='sheet-cont'
          detent="content-height"

        >
          <div >
            <Sheet.Container style={{boxShadow:'none'}}>
              <Sheet.Content>
                <Card className="bottom-card" style={{top:'0',position:'relative'}}>
                  <Row className=" bottom-row">
                    <Col sm={12}>
                      <Row className="custom-card">
                        <Col sm={12} className="d-flex  align-end">
                          <div>
                            <h4 className="mb-0 f-w-600">
                              {destinationData?.to}
                            </h4>
                            <p className="mb-2 light-heading">
                              {destinationData?.to_floor_plan}
                            </p>
                          </div>
                          <div>
                            <div
                              className="close-icon mt-1"
                              onClick={toggleArrowDirection}
                            >
                              {isArrowUp ? (
                                <IoIosArrowUp />
                              ) : (
                                <IoIosArrowDown />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {type > 0 ? (
                        <>
                          {!isArrowUp && (
                            <Row className="mt-2">
                              <Col
                                sm={12}
                                className="d-flex align-items-center"
                              >
                                <Button
                                  size="small"
                                  key="4"
                                  className="custom-button-new"
                                  block
                                  onClick={() => onEndDirection("2")}
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px"
                                  }}
                                >
                                  {"End directions"}
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </>
                      ) : (
                        <>
                          <Row className="" style={{ zIndex: "2" }}>
                            <Col
                              md={12}
                              className="d-flex align-items-center"
                              style={{ justifyContent: "space-between" }}
                            >
                              <Button
                                id="previousButton"
                                size="small"
                                key="4"
                                className="btn btn-scndry"
                                block
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px"
                                }}
                                onClick={() => {
                                  handleNextPreviousClick(
                                    selTraversibleDetails?.isNext,
                                    "previous"
                                  );
                                }}
                                disabled={
                                  selTraversibleDetails?.from_floor_id ==
                                  selFloorData?.enc_id
                                }
                              >
                                Previous floor
                              </Button>
                              <Button
                                size="small"
                                key="4"
                                className={`btn btn-primary`}
                                style={{ marginRight: "10px" }}
                                block
                                onClick={() => {
                                  handleNextPreviousClick(
                                    selTraversibleDetails?.isNext ?? 0,
                                    "next"
                                  );
                                }}
                                disabled={
                                  selTraversibleDetails?.to_floor_id ==
                                  selFloorData?.enc_id
                                }
                              >
                                {"Next floor"}
                              </Button>
                            </Col>
                          </Row>
                          {!isArrowUp && (
                            <Row className="mt-3">
                              <Col
                                sm={12}
                                className="d-flex align-items-center"
                              >
                                <Button
                                  size="small"
                                  key="4"
                                  className={`btn
                        
                            custom-button-new
                            `}
                                  block
                                  onClick={() => onEndDirection("2")}
                                  style={{
                                    marginLeft: "10px",
                                    marginRight: "10px"
                                  }}
                                >
                                  {"End directions"}
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Sheet.Content>
            </Sheet.Container>
          </div>
        </Sheet>

      </Main>
      
      <VerticalTransportModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        verticalTransport={verticalData}
        ChangeSvgColorPassingBE={ChangeSvgColorPassingBE}
        onSelectVerticalTransport={onSelectVerticalTransport}
        selFloorData={selFloorData}
        destinationData={destinationData}
        handleWheelchairCheck={handleWheelchairCheck}
        onEndDirection={onEndDirection}
      />
    </>
  );
}

export default ShowDestination;

