import { fabric } from "fabric";


export const removeFabricObjectsByName = (canvas, name) => {
    canvas?.forEachObject(function (obj) {
        if (obj.name == name) {
            canvas.remove(obj);
        }
    });
};

export const reinitialiseFabric = (canvas) => {
    removeFabricObjectsByName(canvas, "tracing");
    removeFabricObjectsByName(canvas, "boundary");
    removeFabricObjectsByName(canvas, "location");
    removeFabricObjectsByName(canvas, "product");
    removeFabricObjectsByName(canvas, "beacon");
    removeFabricObjectsByName(canvas, "amenity");
    removeFabricObjectsByName(canvas, "safety");
    removeFabricObjectsByName(canvas, "vertical");
    removeFabricObjectsByName(canvas, "short_path");
    removeFabricObjectsByName(canvas, "text");
    removeFabricObjectsByName(canvas, "arrow");

};

export const pinNameTextBringtoFront = (canvas) => {
    canvas?.forEachObject(function (obj) {
        if (obj.type === 'group') {
            obj?.getObjects().forEach((rect) => {
                if (rect?.type === 'text' || rect?.type === 'rect') {
                    obj.bringForward(rect)
                    canvas.renderAll();
                }
            })
        }
    })
}

export const canvasBackGroundColor = (canvas, color) => {
    // const rgbColor = color ? hexToRgb(color, 0.4) : '#F6F7F3';
    const rgbColor = color ?? '#F6F7F3';
    canvas.backgroundColor = rgbColor;
    canvas.renderAll();
}

export function getSquareCoordinates(centerX, centerY, sideLength) {
    const halfSide = sideLength / 2;

    const topLeftX = centerX - halfSide;
    const topLeftY = centerY - halfSide;

    const topRightX = centerX + halfSide;
    const topRightY = centerY - halfSide;

    const bottomLeftX = centerX - halfSide;
    const bottomLeftY = centerY + halfSide;

    const bottomRightX = centerX + halfSide;
    const bottomRightY = centerY + halfSide;
    return [
        { x: topLeftX, y: topLeftY },
        { x: topRightX, y: topRightY },
        { x: bottomRightX, y: bottomRightY },
        { x: bottomLeftX, y: bottomLeftY }
    ];
}

export const ChangeSvgColorPassingBE = (svg, color) => {
    const originalSvgString = svg;
    const desiredColor = color;
    const parser = new DOMParser();
    const doc = parser.parseFromString(originalSvgString, "image/svg+xml");
    const svgElement = doc.documentElement;

    const pathElement = svgElement.querySelector("#svg_1");

    pathElement.setAttribute("fill", desiredColor);

    const updatedSvgString = new XMLSerializer().serializeToString(svgElement);

    return updatedSvgString;
};

export function applyZoom(canvas, centerX, centerY, zoom, type,) {
    canvas.selection = false;

    if (zoom > 20) zoom = 20;
    if (zoom < 0.05) zoom = 0.05;

    const currentZoom = canvas.getZoom();
    canvas.forEachObject((obj) => {
        const center = obj.getCenterPoint();
        if (
            obj.name != "short_path" &&
            obj.name != "svg_refImage" &&
            // obj.name != "arrow"
            // &&
            obj.name != "tracing" &&
            obj.name != "boundary" &&
            obj.name != "text"
        ) {
            if (obj.type == "group" && !obj?.id && obj.name != "text") {
                obj.forEachObject((obj1) => {
                    const center = obj1.getCenterPoint();
                    const initialLeft = obj.initialLeft;
                    const initialTop = obj.initialTop;
                    const initialZoom = obj.initialZoom ?? 1;
                    const currentZoom = canvas.getZoom();
                    obj1.set({
                        ignoreZoom: true,
                        skipAbsolute: true,
                        scaleX: 1 / canvas?.getZoom(),
                        scaleY: 1 / canvas?.getZoom(),
                        strokeWidth: 1
                    });
                });
            }

            /* Text maintain size */
            // if (obj?.type === 'text' && obj?.name === 'text') {
            //   obj.originalFontSize = obj.fontSize;
            // }
            // else if (obj?.type === 'text' && obj?.name === 'text') {
            //   obj.fontSize = obj.originalFontSize * (1 / zoom);
            //   // obj.setCoords();
            // }
            else {
                obj.set({
                    ignoreZoom: true,
                    skipAbsolute: true,
                    scaleX: 1 / canvas?.getZoom(),
                    scaleY: 1 / canvas?.getZoom()
                });
            }
            obj.setPositionByOrigin(center, "center", "center");
        }
        // else if (obj.name == "short_path") {
        //     const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
        //     let strokeWidth = originalStrokeWidth / zoom;
        //     strokeWidth = Math.max(Math.min(strokeWidth, originalStrokeWidth), 0.3);
        //     obj.set({ strokeWidth: strokeWidth });
        // }
        else if (obj.name == "short_path") {
            const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
            const initialZoom = obj.initialZoom || 1; // Fallback to 1 if initial zoom is not set
            const currentZoom = canvas.getZoom();
            const strokeWidth = originalStrokeWidth * (initialZoom / currentZoom);
            obj.set({ strokeWidth: strokeWidth });
            obj.setPositionByOrigin(center, "center", "center");
        }

        /* Adjust opacity of pins in zoom out */
        if (obj?.name != "tracing" &&
            obj?.types != 'highlight_pin' &&
            obj?.name != "svg_refImage" &&
            obj?.name != "short_path" &&
            obj?.name != "text" &&
            obj?.name != "safety" &&
            obj?.name != "amenity" &&
            obj?.name != "vertical" &&
            obj?.name != "boundary" &&
            // canvas.getZoom() <= 0.35
            obj?.name != "arrow-head" &&
            currentZoom <= 0.35
            // canvas.getZoom() <= 0.35
        ) {

            if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
                // console.log(zoom, '0.6')
                obj.set({ opacity: 0.6 })
            } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
                obj.set({ opacity: 0.3 })
                // console.log(zoom, '0.3')
            } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
                obj.set({ opacity: 0.1 })
                // console.log(zoom, '0.1')
            } else if (canvas.getZoom() < 0.2) {
                // console.log(zoom, '0')
                obj.set({ opacity: 0 })
            }
        } else if (obj?.name != "boundary") {
            obj.set({ opacity: 1 })
        }
        canvas.requestRenderAll();
    });
    if (type == "mouse") {
        canvas.zoomToPoint({ x: centerX, y: centerY }, zoom);
    } else {
        const easingFactor = 0.3;
        const smoothZoom = canvas.getZoom() + (zoom - canvas.getZoom()) * easingFactor;
        canvas.zoomToPoint({ x: centerX, y: centerY }, smoothZoom);
    }
    canvas.requestRenderAll();
    // lastZoom = zoom;
}

export function applyPinchZoom(canvas) {
    // console.log('pinchzoom')
    canvas.forEachObject((obj) => {
        const center = obj.getCenterPoint();

        if (
            obj.name != "short_path" &&
            obj.name != "svg_refImage" &&
            // obj.name != "arrow"
            // &&
            obj.name != "tracing" &&
            obj.name != "boundary" &&
            obj.name != "text"
        ) {
            if (obj.type == "group" && !obj?.id && obj.name != "text") {
                obj.forEachObject((obj1) => {
                    obj1.set({
                        ignoreZoom: true,
                        skipAbsolute: true,
                        scaleX: 1 / canvas?.getZoom(),
                        scaleY: 1 / canvas?.getZoom(),
                        strokeWidth: 1
                    });
                });
            }
            else {
                obj.set({
                    ignoreZoom: true,
                    skipAbsolute: true,
                    scaleX: 1 / canvas?.getZoom(),
                    scaleY: 1 / canvas?.getZoom()
                });
            }
            obj.setPositionByOrigin(center, "center", "center");

            canvas.requestRenderAll();
        }
        // else if (obj.name == "short_path") {
        //     const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
        //     let strokeWidth = originalStrokeWidth / canvas?.getZoom();
        //     strokeWidth = Math.max(Math.min(strokeWidth, originalStrokeWidth), 0.3);
        //     obj.set({ strokeWidth: strokeWidth });
        // }
        else if (obj.name == "short_path") {
            const originalStrokeWidth = obj.originalStrokeWidth || obj.strokeWidth; // Fallback to current if original is not set
            const initialZoom = obj.initialZoom || 1; // Fallback to 1 if initial zoom is not set
            const currentZoom = canvas.getZoom();
            const strokeWidth = originalStrokeWidth * (initialZoom / currentZoom);
            obj.set({
                strokeWidth: strokeWidth,
                ignoreZoom: true,
                skipAbsolute: true,
            });
            obj.setPositionByOrigin(center, "center", "center");

        }

        /* Adjust opacity of pins in zoom out */
        if (obj?.name != "tracing" &&
            obj?.name != "svg_refImage" &&
            obj?.types != 'highlight_pin' &&
            obj?.name != "short_path" &&
            obj?.name != "text" &&
            obj?.name != "safety" &&
            obj?.name != "amenity" &&
            obj?.name != "vertical" &&
            obj?.name != "boundary" &&
            obj?.name != "arrow-head" &&
            canvas.getZoom() <= 0.35
        ) {

            if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
                obj.set({ opacity: 0.6 })
            } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
                obj.set({ opacity: 0.3 })
            } else if (canvas.getZoom() < 0.25 && canvas.getZoom() >= 0.2) {
                obj.set({ opacity: 0.1 })
            } else if (canvas.getZoom() < 0.1) {
                obj.set({ opacity: 0 })
            }
            // if (canvas.getZoom() <= 0.35 && canvas.getZoom() > 0.3) {
            //     console.log(zoom, '0.5')
            //     obj.set({ opacity: 0.5 })
            // } else if (canvas.getZoom() < 0.3 && canvas.getZoom() >= 0.25) {
            //     obj.set({ opacity: 0.2 })
            //     console.log(zoom, '0.2')
            // } else if (canvas.getZoom() < 0.2) {
            //     console.log(zoom, '0')
            //     obj.set({ opacity: 0 })
            // }


        } else if (obj?.name != "boundary") {
            obj.set({ opacity: 1 })
        }

        canvas.requestRenderAll();

    });

}

// export const rotateCanvas = (degrees, canvas) => {
//     let canvasCenter = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
//     let radians = fabric.util.degreesToRadians(degrees);

//     canvas.forEachObject(function (obj) {
//         if (obj.name === "tracing") {
//             // Rotate the tracing object and adjust its position
//             let objectOrigin = new fabric.Point(obj.left, obj.top);
//             let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians);

//             obj.set({
//                 left: new_loc.x,
//                 top: new_loc.y,
//                 angle: obj.angle + degrees
//             });
//         } else {
//             // Keep other objects at the same position but adjust for canvas rotation
//             let objectOrigin = new fabric.Point(obj.left, obj.top);
//             let new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, radians);

//             obj.set({
//                 left: new_loc.x,
//                 top: new_loc.y,
//                 angle: obj.angle + degrees
//             });
//             if (!["short_path", "arrow", "text", "boundary"].includes(obj.name)) {
//                 obj.rotate(0)
//             }
//         }

//         obj.setCoords();
//     });

//     /* Background image rotate test */
//     if (canvas.backgroundImage) {
//         let bg = canvas.backgroundImage;
//         let bgOrigin = new fabric.Point(bg.left, bg.top);
//         let newBgLoc = fabric.util.rotatePoint(bgOrigin, canvasCenter, radians);
//         bg.set({
//             left: newBgLoc.x,
//             top: newBgLoc.y,
//             angle: (bg.angle || 0) + degrees,
//             originX: 'center',
//             originY: 'center'
//         });
//         bg.setCoords(); 
//     }

//     canvas.requestRenderAll();
// };
/** */


export const rotateCanvas = (degrees, canvas, fixedPoint,tilesRef) => {
    let radians = fabric.util.degreesToRadians(degrees);

    canvas.forEachObject(function (obj) {
        if (obj.name === "tracing" || obj.name === "arrow-head" || obj.name === "svg_refImage") {
            // Rotate the tracing object around the fixed point
            let objectOrigin = new fabric.Point(obj.left, obj.top);
            let new_loc = fabric.util.rotatePoint(objectOrigin, fixedPoint, radians);

            obj.set({
                left: new_loc.x,
                top: new_loc.y,
                angle: obj.angle + degrees
            });
        } else {
            // Rotate other objects around the fixed point
            let objectOrigin = new fabric.Point(obj.left, obj.top);
            let new_loc = fabric.util.rotatePoint(objectOrigin, fixedPoint, radians);

            obj.set({
                left: new_loc.x,
                top: new_loc.y,
                angle: obj.angle + degrees
            });
            if (!["short_path", "arrow", "text", "boundary"].includes(obj.name)) {
                obj.rotate(0);
            }
        }

        obj.setCoords();
    });

    if (canvas.backgroundImage) {
        let bg = canvas.backgroundImage;
        let bgOrigin = new fabric.Point(bg.left, bg.top);
        let newBgLoc = fabric.util.rotatePoint(bgOrigin, fixedPoint, radians);
        bg.set({
            left: newBgLoc.x,
            top: newBgLoc.y,
            angle: (bg.angle || 0) + degrees,
            // originX: 'center',
            // originY: 'center'
        });
        bg.setCoords();
    }

    if (tilesRef.current?.length > 0 && tilesRef.current !== "no-image") {
        tilesRef.current = tilesRef.current.map(tile => {
          // Create a point from the tile's current position.
          const tileOrigin = new fabric.Point(tile.left, tile.top);
          // Rotate this point around the fixed point.
          const newTileOrigin = fabric.util.rotatePoint(tileOrigin, fixedPoint, radians);
          return {
            ...tile,
            left: newTileOrigin.x,
            top: newTileOrigin.y,
            right: newTileOrigin.x + 250, // assuming 250 is your TILE_SIZE
            bottom: newTileOrigin.y + 250,
            angle: tile.angle + degrees
          };
        });
    }
    
    canvas.requestRenderAll();
};


export const addApointToRotate = (canvas, midpoint) => {
    let centerPoint;
    const transform = canvas.viewportTransform;
    if (midpoint) {
        centerPoint = midpoint
    } else if (transform) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const topLeft = fabric.util.transformPoint({ x: 0, y: 0 }, fabric.util.invertTransform(transform));
        const bottomRight = fabric.util.transformPoint({ x: viewportWidth, y: viewportHeight }, fabric.util.invertTransform(transform));

        const visibleArea = {
            x1: topLeft.x,
            y1: topLeft.y,
            x2: bottomRight.x,
            y2: bottomRight.y,
        };
        const xCenter = (visibleArea.x1 + visibleArea.x2) / 2;
        const yCenter = (visibleArea.y1 + visibleArea.y2) / 2;
        centerPoint = {
            x: xCenter,
            y: yCenter
        }

    }
    const circle = new fabric.Circle({
        left: centerPoint.x,
        top: centerPoint.y,
        radius: 5,
        fill: "rgba(0,0,0,0)",
        // fill: "red",
        originX: "center",
        originY: "center",
        selectable: false,
        types: "centerPointPin",
        name: "centerPointPin"
    });
    // Remove existing visible area rectangles before adding a new one
    const existingCircle = canvas.getObjects('circle').filter(obj => obj.fill === 'rgba(0,0,0,0)');
    existingCircle.forEach(cir => canvas.remove(cir));

    canvas.add(circle);
    canvas.renderAll();
}

export const rotateDragToPoint = (canvas, point) => {
    // canvas.setZoom(1); // reset zoom so pan actions work as expected
    let vpw = canvas.width / 1;
    let vph = canvas.height / 1;
    let x = point.x - vpw / 2; // x is the location where the top left of the viewport should be
    let y = point.y - vph / 2; // y idem
    canvas.absolutePan({ x, y });
    // canvas.setZoom(1);
};

export const dragToPoint = (canvas, point) => {
    canvas.setZoom(1); // reset zoom so pan actions work as expected
    let vpw = canvas.width / 1;
    let vph = canvas.height / 1;
    let x = point.x - vpw / 2; // x is the location where the top left of the viewport should be
    let y = point.y - vph / 2; // y idem
    canvas.absolutePan({ x, y });
    canvas.setZoom(1);
};

export const getAngleBetweenTouches = (touches) => {
    let [touch1, touch2] = touches;
    let dx = touch2.pageX - touch1.pageX;
    let dy = touch2.pageY - touch1.pageY;
    return Math.atan2(dy, dx) * (180 / Math.PI);
};

export const canvasBackGroundImage = (canvas, image, data,istaskrendered=false,scale) => {
    if (data?.cropped_path && data?.show_image == 1) {
        const isSvg = data?.cropped_path?.endsWith('.svg')
        if (isSvg) {
             const svg_Blob = data?.svg_data ?? ''
                  /* Add svg as object */
                  removeFabricObjectsByName(canvas, 'svg_refImage');
                  removeFabricObjectsByName(canvas, "backgroundRect");
            
                  canvas.setBackgroundImage(
                      null,
                      canvas.renderAll.bind(canvas)
                  );
                  const scaleFactor = data?.img_size ? JSON.parse(data?.img_size) : 1;
                  fabric.loadSVGFromString(svg_Blob, (objects, options) => {
                      // const svg = fabric.util.groupSVGElements(objects, options);
                      const fabricObjects = [];
                      objects.forEach(obj => {
                          let fabricObj;
                  
                          switch (obj.type) {
                              case 'path':
                                  fabricObj = new fabric.Path(obj.path, {
                                      fill: obj.fill,
                                      stroke: obj.stroke,
                                      // strokeWidth: obj.strokeWidth * scaleFactor,
                                      strokeWidth: obj.strokeWidth,
                                      left: obj.left * scaleFactor,
                                      top: obj.top * scaleFactor,
                                      scaleX: scaleFactor,
                                      scaleY: scaleFactor,
                                      objectCaching: false,
                                      // id: 'svg_refImage',
                                      // name: 'svg_refImage',
                                  });
                                  break;
                  
                              case 'line':
                                  fabricObj = new fabric.Line(
                                      [obj.x1 * scaleFactor, obj.y1 * scaleFactor, obj.x2 * scaleFactor, obj.y2 * scaleFactor],
                                      {
                                          stroke: obj.stroke,
                                          strokeWidth: obj.strokeWidth * scaleFactor,
                                          left: obj.left * scaleFactor,
                                          top: obj.top * scaleFactor,
                                          // id: 'svg_refImage',
                                          // name: 'svg_refImage'
                                      }
                                  );
                                  break;
                  
                              case 'polyline':
                                  fabricObj = new fabric.Polyline(
                                      obj.points.map(point => ({ x: point.x * scaleFactor, y: point.y * scaleFactor })),
                                      {
                                          fill: obj.fill,
                                          stroke: obj.stroke,
                                          strokeWidth: obj.strokeWidth * scaleFactor,
                                          left: obj.left * scaleFactor,
                                          top: obj.top * scaleFactor,
                                          // id: 'svg_refImage',
                                          // name: 'svg_refImage'
                                      }
                                  );
                                  break;
                  
                              case 'polygon':
                                  fabricObj = new fabric.Polygon(
                                      obj.points.map(point => ({ x: point.x * scaleFactor, y: point.y * scaleFactor })),
                                      {
                                          fill: obj.fill,
                                          stroke: obj.stroke,
                                          strokeWidth: obj.strokeWidth * scaleFactor,
                                          left: obj.left * scaleFactor,
                                          top: obj.top * scaleFactor,
                                          // id: 'svg_refImage',
                                          // name: 'svg_refImage'
                                      }
                                  );
                                  break;
                  
                              case 'rect':
                                  fabricObj = new fabric.Rect({
                                      width: obj.width * scaleFactor,
                                      height: obj.height * scaleFactor,
                                      fill: obj.fill,
                                      stroke: obj.stroke,
                                      strokeWidth: obj.strokeWidth * scaleFactor,
                                      left: obj.left * scaleFactor,
                                      top: obj.top * scaleFactor,
                                      rx: (obj.rx || 0) * scaleFactor, // Rounded corners
                                      ry: (obj.ry || 0) * scaleFactor,
                                      // id: 'svg_refImage',
                                      // name: 'svg_refImage',
                                  });
                                  break;
                  
                              case 'circle':
                                  const { valueX, valuey } = getCirclePositionAdjustment(scaleFactor);
                                  const adjustedLeft = (obj.left * scaleFactor) - (obj.radius * (scaleFactor - valueX));
                                  const adjustedTop = (obj.top * scaleFactor) - (obj.radius * (scaleFactor - valuey));
                      
                                  fabricObj = new fabric.Circle({
                                      radius: obj.radius * scaleFactor,
                                      fill: obj.fill,
                                      stroke: obj.stroke,
                                      strokeWidth: obj.strokeWidth * scaleFactor,
                                      left: adjustedLeft, 
                                      top: adjustedTop,  
                                      // id: 'svg_refImage',
                                      // name: 'svg_refImage',
                                  });
                                  break;
                  
                              default:
                                  console.warn(`Unsupported SVG type: ${obj.type}`);
                                  return;
                          }
                  
                          fabricObjects.push(fabricObj); 
                      });
                      // canvas.current.add(...fabricObjects);
                      const svg = new fabric.Group(fabricObjects, {
                          selectable: false,
                          originX: "center",
                          originY: "center",
                          left: data?.width / 2,
                          top: data?.height / 2,
                          scaleX: scaleFactor,
                          scaleY: scaleFactor,
                          objectCaching: false,
                          id: 'svg_refImage',
                          name: 'svg_refImage'
                      });
              console.log(scaleFactor,svg,"zoom ?? zoomInOutzoom ?? zoomInOutzoom ?? zoomInOut");
            
                      canvas.add(svg);
                      canvas.sendToBack(svg);
                      canvas.renderAll();
                      // canvas.current.renderAll();
                  });

        } else {
            removeFabricObjectsByName(canvas, 'svg_refImage');
            // console.log(image,"image as background image")
            // fabric.Image.fromURL(image, function (img) {
            //     const canvasWidth = canvas.getWidth();
            //     const canvasHeight = canvas.getHeight();
            //     const left = (canvasWidth - img.width) / 2;
            //     const top = (canvasHeight - img.height) / 2;
            //     const imageSize = normalizeImgSize(data?.img_size);
            //     img.scale(imageSize);
            //     canvas.setBackgroundImage(img, 
            //         // canvas.renderAll.bind(canvas),
            //         () => {
            //             const slightZoom = 0.000000000000000001;
            //             const centerPoint = new fabric.Point(
            //                 canvas.getWidth() / 2,
            //                 canvas.getHeight() / 2
            //             );

            //             canvas.zoomToPoint(centerPoint, canvas.getZoom() + slightZoom);
            //             canvas.renderAll();
            //         },
            //         {
            //         left: data?.width / 2,
            //         top: data?.height / 2,
            //         originX: 'center',
            //         originY: 'center'
            //     });
            // });

            fabric.Image.fromURL(image, function (img) {

                const imageSize = normalizeImgSize(data?.img_size);
                img.originX = 'center';
                img.originY = 'center';
                img.left = data?.width / 2;
                img.top = data?.height / 2; 
                img.scale(imageSize);
            

                console.log(img,"sdkfbjksdbfbds bg image");
                // canvas.setBackgroundImage(img, () => {
                //     const slightZoom = 1e-15;
                //     const centerPoint = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
                //     canvas.zoomToPoint(centerPoint, canvas.getZoom() + slightZoom);
                //     canvas.renderAll();
                // });
            });
        }
    } else {
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
        removeFabricObjectsByName(canvas, 'svg_refImage');
    }

    if(istaskrendered){
        istaskrendered.current = true
    }
}

// render background image tile by tile
export const setCanvasBackgroundImageAsimage = async(imageUrl,data,tilesRef,canvas,setTiles) =>{
  try {
    // let response = await fetch(`https://smp-admin.theworkpc.com/images/1.png`);
    // let response = await fetch(`https://smp-admin.theworkpc.com/images/lv-03.svg`);
    let response = await fetch(imageUrl);
    let blob = await response.blob();
    
    let objectURL = URL.createObjectURL(blob);

    const newTiles = await splitImageIntoTiles(objectURL,data,canvas);
    setTiles(newTiles);
    tilesRef.current = newTiles
  } catch (error) {
    console.error("Error loading image:", error);
  }
}

const splitImageIntoTiles = (imageSrc, data) => { 
    const TILE_SIZE = 250;
    return new Promise((resolve) => {
        const img = new Image();
        img.src = imageSrc;
        
        img.onload = () => {
        const imageSize = normalizeImgSize(data.img_size);
        const { width: scaledWidth, height: scaledHeight } = getScaledDimensions(img, imageSize);
        
        const imageLeft = data.width / 2 - scaledWidth / 2;
        const imageTop = data.height / 2 - scaledHeight / 2;

        const cols = Math.ceil(scaledWidth / TILE_SIZE);
        const rows = Math.ceil(scaledHeight / TILE_SIZE);

        const tileList = [];
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = TILE_SIZE;
        tempCanvas.height = TILE_SIZE;
        const ctx = tempCanvas.getContext("2d");

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
            ctx.clearRect(0, 0, TILE_SIZE, TILE_SIZE);
            
            ctx.drawImage(
                img,
                col * TILE_SIZE / imageSize, 
                row * TILE_SIZE / imageSize,
                TILE_SIZE / imageSize,
                TILE_SIZE / imageSize,
                0,
                0,
                TILE_SIZE,
                TILE_SIZE
            );

            const tileX = imageLeft + col * TILE_SIZE;
            const tileY = imageTop + row * TILE_SIZE;

            tileList.push({
                left: tileX,
                top: tileY,
                right: tileX + TILE_SIZE,
                bottom: tileY + TILE_SIZE,
                src: tempCanvas.toDataURL(),
                angle :0
            });
            }
        }

        resolve(tileList);
        };
    });
};

const getScaledDimensions = (img, imageSize) => ({
width: img.width * imageSize,
height: img.height * imageSize
});

export const updateVisibleTiles = (canvas,tilesRef) => {
    if(tilesRef.current !== "no-image"){
      const visibleTiles = tilesRef.current?.filter(tile => isTileVisible(tile, canvas)) || [];
      if(visibleTiles.length > 0){
        renderTilesOnCanvas(canvas, visibleTiles);
      }
    }
};

const isTileVisible = (tile, canvas, margin = 250) => {
    const vpBounds = getViewportBounds(canvas);
    return (
      tile.right > vpBounds.tl.x - margin &&
      tile.left < vpBounds.br.x + margin &&
      tile.bottom > vpBounds.tl.y - margin &&
      tile.top < vpBounds.br.y + margin
    );
};

 const getViewportBounds = (canvas) => {
    // Get the current viewport transform and its inverse.
    const vt = canvas.viewportTransform;
    const invVT = fabric.util.invertTransform(vt);
  
    // Top-left and bottom-right in canvas coordinates.
    const canvasTL = new fabric.Point(0, 0);
    const canvasBR = new fabric.Point(canvas.getWidth(), canvas.getHeight());
  
    // Convert canvas coordinates to world coordinates.
    const worldTL = fabric.util.transformPoint(canvasTL, invVT);
    const worldBR = fabric.util.transformPoint(canvasBR, invVT);
  
    return { tl: worldTL, br: worldBR };
  };

const renderTilesOnCanvas = async (canvas, visibleTiles) => {
    try {
    const images = await Promise.all(visibleTiles.map((item)=>loadImage(item)));

    if (canvas.backgroundImage) {
        canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
    }
    const group = new fabric.Group(images, {
        selectable: false,
        evented: false,
        objectCaching: false,
    });
    
    canvas.setBackgroundImage(group, canvas.renderAll.bind(canvas));
    } catch (error) {
        console.error("Error loading images:", error);
    }
};

const loadImage = (tile) => {
    return new Promise((resolve, reject) => {
        fabric.Image.fromURL(tile.src, (img) => {
        if (img) {
            img.set({
            left: tile.left,
            top: tile.top,
            angle: tile.angle,
            scaleX: 1,  
            scaleY: 1,  
            // originX: 'center',
            // originY: 'center'
            });

            // img.width += overlap;
            // img.height += overlap;
            // img.left -= overlap / 2;
            // img.top -= overlap / 2;

            resolve(img);
        } else {
            reject(new Error("Failed to load image"));
        }
        });
    });
};

// bg functions over

// Example: background lazy-loading
// export const canvasBackGroundImage = (canvas, imageUrl, data, isTaskRendered = false) => {
//   // If there's no cropped_path or the image is disabled, remove any existing background
//   if (!data?.cropped_path || data?.show_image !== 1) {
//     canvas.setBackgroundImage(null, canvas.renderAll.bind(canvas));
//     removeFabricObjectsByName(canvas, 'svg_refImage');
//     if (isTaskRendered) isTaskRendered.current = true;
//     return;
//   }

//   // 1) Load a smaller placeholder or do nothing if you have no placeholder
//   const placeholderUrl = data?.placeholder_path; // e.g., a low-res or blank image
//   if (placeholderUrl) {
//     // Load a small placeholder image first
//     fabric.Image.fromURL(placeholderUrl, function (placeholderImg) {
//       placeholderImg.originX = 'center';
//       placeholderImg.originY = 'center';
//       placeholderImg.left = data?.width / 2;
//       placeholderImg.top = data?.height / 2;

//       // Optionally scale the placeholder to fit canvas
//       placeholderImg.scaleToWidth(canvas.getWidth());
//       placeholderImg.scaleToHeight(canvas.getHeight());

//       // Set placeholder as background
//       canvas.setBackgroundImage(placeholderImg, () => {
//         canvas.renderAll();
//         // Now that placeholder is shown, lazy-load the large image
//         loadFullImage(canvas, imageUrl, data, isTaskRendered);
//       });
//     });
//   } else {
//     // No placeholder => directly lazy-load the final image
//     loadFullImage(canvas, imageUrl, data, isTaskRendered);
//   }

//   isTaskRendered.current = true;
// };

function loadFullImage(canvas, imageUrl, data, isTaskRendered) {
  fabric.Image.fromURL(imageUrl, function (img) {

    const imageSize = normalizeImgSize(data?.img_size);
    img.originX = 'center';
    img.originY = 'center';
    img.left = data?.width / 2;
    img.top = data?.height / 2;
    img.scale(imageSize);

    canvas.setBackgroundImage(img, () => {
      const slightZoom = 1e-15;
      const centerPoint = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
      canvas.zoomToPoint(centerPoint, canvas.getZoom() + slightZoom);
      canvas.renderAll();
    });
  });
}


function normalizeImgSize(value) {
    if (typeof value === 'string') {
        return parseFloat(value.replace(/["]/g, '')); 
    }
    return value; 
}



const getCirclePositionAdjustment = (scaleFactor) => {
    const referenceScale = 0.8839779005524862
    const baseOffsetX = 1.15;
    const baseOffsetY = 0.47;

    let valueX = calculateNumber(referenceScale, scaleFactor, baseOffsetX);
    let valuey = calculateNumber(referenceScale, scaleFactor, baseOffsetY);

    return {
        valueX,
        valuey
    };
};

function calculateNumber(referenceScale,scaleFactor, baseOffsetX) {
    let scaleFactorAt08 = referenceScale;
    let baseOffsetXAt08 = baseOffsetX;

    let scaleFactorAt1 = scaleFactor;
    let baseOffsetXAt1 = (baseOffsetXAt08 / scaleFactorAt08) * scaleFactorAt1;
    return baseOffsetXAt1
}



// export function fitObjectsInView (canvas, object1, object2){
//     console.log(object1, object2,"object1, object2")
//     if(object1 && object2){

//         // Get bounding boxes for both objects
//         const rect1 = object1.getBoundingRect(true); // True accounts for transformations
//         const rect2 = object2.getBoundingRect(true);
      
//         // Combine the bounding boxes
//         const minX = Math.min(rect1.left, rect2.left);
//         const minY = Math.min(rect1.top, rect2.top);
//         const maxX = Math.max(rect1.left + rect1.width, rect2.left + rect2.width);
//         const maxY = Math.max(rect1.top + rect1.height, rect2.top + rect2.height);
      
//         // Calculate the bounding box dimensions
//         const boundingWidth = maxX - minX;
//         const boundingHeight = maxY - minY;
      
//         // Get the canvas dimensions
//         const canvasWidth = canvas.width;
//         const canvasHeight = canvas.height;
      
//         // Calculate the zoom factor to fit the bounding box into the canvas
//         const zoomX = canvasWidth / boundingWidth;
//         const zoomY = canvasHeight / boundingHeight;
//         const newZoom = Math.min(zoomX, zoomY);
      
//         // Center the bounding box on the canvas
//         const centerX = minX + boundingWidth / 2;
//         const centerY = minY + boundingHeight / 2;
      
//         // Update canvas zoom and pan
//         canvas.setZoom(newZoom);
//         console.log(newZoom,"newZoomnewZoomnewZoom")
        
        
//         // Adjust the canvas viewport to center the objects
//         const viewportTransform = canvas.viewportTransform;
//         if (viewportTransform) {
//             viewportTransform[4] = canvasWidth / 2 - centerX * newZoom;
//             viewportTransform[5] = canvasHeight / 2 - centerY * newZoom;
//             canvas.requestRenderAll();
//         }
//         return {
//             newZoom,
//             centerX,
//             centerY
//         }
//     }
//   };


// export function fitObjectsInView(canvas, object1, object2,shortestPath) {
//     console.log(object1, object2,shortestPath,"shortestPathshortestPathshortestPath");
//     if (object1 && object2) {
//       const reservedSpace = { top: 100, bottom: 150 };
//       const effectiveCanvasHeight = canvas.height - 350;
//       const canvasWidth = canvas.width;

//       const {
//         x: x1,
//         y: y1,
//         width: width1 = 30,
//         height: height1 = 30,
//       } = object1;
//       const {
//         x: x2,
//         y: y2,
//         width: width2 = 30,
//         height: height2 = 30,
//       } = object2;

//       const minX = Math.min(x1, x2);
//       const minY = Math.min(y1, y2);
//       const maxX = Math.max(x1 + width1, x2 + width2);
//       const maxY = Math.max(y1 + height1, y2 + height2);

//       const boundingWidth = maxX - minX;
//       const boundingHeight = maxY - minY;

//       const zoomX = canvasWidth / boundingWidth;
//       const zoomY = effectiveCanvasHeight / boundingHeight;
//       let newZoom = Math.min(zoomX, zoomY);

//       newZoom *= 0.9;

//       const centerX = minX + boundingWidth / 2;
//       const centerY = minY + boundingHeight / 2;

//       canvas.setZoom(newZoom);

//       const viewportTransform = canvas.viewportTransform;
//       if (viewportTransform) {
//         viewportTransform[4] = canvasWidth / 2 - centerX * newZoom;
//         viewportTransform[5] =
//           (canvas.height - reservedSpace.top - reservedSpace.bottom) / 2 -
//           centerY * newZoom +
//           reservedSpace.top;

//         const absolutePanX = -viewportTransform[4] / newZoom;
//         const absolutePanY = -viewportTransform[5] / newZoom;

//         canvas.absolutePan(new fabric.Point(absolutePanX, absolutePanY));
//         canvas.requestRenderAll();
//       }

//       // console.log({ newZoom, centerX, centerY }, "Viewport adjustments applied");
//       return {
//         newZoom,
//         centerX,
//         centerY,
//       };
//     }
// }

export function fitObjectsInView(canvas, objects) {
    if (!objects || objects.length === 0) return;
  
    const reservedSpace = { top: 100, bottom: 150 };
    const effectiveCanvasHeight = canvas.height - 350;
    const canvasWidth = canvas.width;
  
    // Initialize min/max values with the first object's properties
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  
    objects.forEach(({ x, y, width = 30, height = 30 }) => {
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x + width);
      maxY = Math.max(maxY, y + height);
    });
  
    const boundingWidth = maxX - minX;
    const boundingHeight = maxY - minY;
  
    const zoomX = canvasWidth / boundingWidth;
    const zoomY = effectiveCanvasHeight / boundingHeight;
    let newZoom = Math.min(zoomX, zoomY) * 0.9; // Apply a margin
  
    const centerX = minX + boundingWidth / 2;
    const centerY = minY + boundingHeight / 2;
  
    canvas.setZoom(newZoom);
  
    const viewportTransform = canvas.viewportTransform;
    if (viewportTransform) {
      viewportTransform[4] = canvasWidth / 2 - centerX * newZoom;
      viewportTransform[5] =
        (canvas.height - reservedSpace.top - reservedSpace.bottom) / 2 -
        centerY * newZoom +
        reservedSpace.top;
  
      const absolutePanX = -viewportTransform[4] / newZoom;
      const absolutePanY = -viewportTransform[5] / newZoom;
  
      canvas.absolutePan(new fabric.Point(absolutePanX, absolutePanY));
      canvas.requestRenderAll();
    }
  
    return {
      newZoom,
      centerX,
      centerY,
    };
  }
